import styled from 'styled-components';

export const Container = styled.div`
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`

export const Title = styled.h1`
    color: #2484DC;
    font-size: 2.5rem;
    width: 100%;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-bottom: 0;
    @media (max-width: 380px) {
        font-size: 2rem;
    }
`

export const Subtite = styled.p`
    font-family: Poppins-Medium;
    color: #FF9A5C;
    font-size: 1.3rem;
    margin: 0rem;
    text-align: center;
    width: 90vw;
    margin-block: 0;
    word-wrap: break-word;
    margin-top: 2rem;
    margin-bottom: 2rem;
`

export const CountryCode = styled.p`
    color: #000;
    font-size: 1rem;
    font-family: Lato-Bold;
    text-align: center;
`
export const ButtonContainer = styled.div`
    position: relative;
    z-index: 100;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 10%;
`