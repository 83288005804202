import { SnackbarAction } from './Snackbar-actions';
import { SnackbarTypes } from './Snackbar-types';


export interface SnackbarState {
    show: boolean,
    message: string,
    type: string,
}


const INITIAL_STATE = {
    show: false,
    message: '',
    type: '',
}


const SnackbarReducer = (
    state: SnackbarState = INITIAL_STATE,
    action: SnackbarAction
) => {
    switch (action.type) {
        case SnackbarTypes.SET_NOTIFY:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export default SnackbarReducer;