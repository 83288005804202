import { VisitorTypes } from './Visitor-types';
import { Dispatch } from 'redux';


export interface VisitorActions {
    type: VisitorTypes,
    payload?: string | boolean | any
}


export const saveInfoStart = () => ({
    type: VisitorTypes.VISITOR_INFO_START,
    payload: null
})

export const saveVisitorInfoSuccess = (data: {
    meetingId: number,
    visitorId: number | undefined,
    orgLogo: string | undefined
}) => ({
    type: VisitorTypes.VISITOR_INFO_SUCCESS,
    payload: data
})

export const saveInfoFail = (error: string) => ({
    type: VisitorTypes.VISITOR_INFO_FAIL,
    payload: error
})

export const deleteInfoStart = () => ({
    type: VisitorTypes.VISITOR_DELETE_START,
    payload: null
})

export const deleteInfoSuccess = () => ({
    type: VisitorTypes.VISITOR_DELETE_SUCCESS,
    payload: true
})

export const deleteInfoFail = (error: string) => ({
    type: VisitorTypes.VISITOR_DELETE_FAIL,
    payload: error
})


export const deleteInfo = () => {
    return (dispatch: Dispatch) => {
        dispatch(deleteInfoStart())
        dispatch(deleteInfoSuccess())
    }
}


