import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`




export const Title = styled.h1`
    color: #2484DC;
    font-size: 1.5rem;
    width: 100%;
    font-family: Lato-Bold;
    text-align: left;
    margin-bottom: 1rem;
    padding: 0 1rem;
`


export const Content = styled.div`
    display: flex; 
    flex: 0.7;
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    max-width: 400px;
    min-width: 270px;
    padding: 0 1rem;
`

export const TermsText = styled.p`
    font-family: Popins-Regular;
    color: #5F5F5F;
    font-size: 1rem;
    text-align: justify;
    text-justify: inter-word;
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
`
