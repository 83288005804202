import React, { useState, useEffect } from 'react';

import {
    Container,
    Title,
    Subtite,
} from './Mobile.styles';

import {
    Button,
    CheckInText
} from '../../Styles'

// styles
import 'react-phone-input-2/lib/style.css'
import './mobileStyles.scss'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { EmailSuccess, MobileNumberSuccess } from '../../Redux/User/User-actions'

import { ErrorCode } from '../LoginForm/LoginForm.styles';
import { RootState } from '../../Redux/root-reducer';
import CountryPhone, { getCountryCode, isValidPhoneNo } from '../../Components/CountryPhone/CountryPhone';
import { removeWhiteSpace, validEmail } from '../../utils';
import TextField from '../../Components/TextField/Textfield';

interface MobileProps {
    setStep: (step: number) => void;
    nextStep: number;
}

interface ErrorType {
    phone: {
        show: boolean,
        message: string,
    }
}

let defaultCountryCode = getCountryCode()?.countryCode?.replace('+', '')

const Mobile: React.FC<MobileProps> = ({ setStep, nextStep }) => {
    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state?.user);
    const [countryCode, setCountryCode] = useState<string>(defaultCountryCode || '');
    const [phoneOrEmail, setPhoneOrEmail] = useState<{ value: string, isValid: boolean }>({ value: '', isValid: true });
    const [error, setError] = useState<ErrorType>({ phone: { show: false, message: '' } });
    const [checkInMode, setCheckinMode] = useState<number>(0);//0 = Both (email and phone), 1 = Email Only, 2 = Phone Only

    useEffect(() => {
        if (user?.visitorEmailEnabled && user?.visitorPhoneEnabled) {
            setCheckinMode(0);
        } else if (user?.visitorEmailEnabled) {
            setCheckinMode(1);
        } else if (user?.visitorPhoneEnabled) {
            setCheckinMode(2);
        }

        setPhoneOrEmail({ value: user?.email || user?.phone || '', isValid: true })
    }, [user]);

    const proceed = () => {
        let body: any = {}
        if (phoneOrEmail?.value?.includes('@')) {
            dispatch(EmailSuccess(phoneOrEmail?.value))
        } else {
            dispatch(MobileNumberSuccess(`${phoneOrEmail?.value}`))
            body["phone"] = `${phoneOrEmail?.value}`;
        }
        setStep(nextStep)
    }

    const formatPhoneValue = () => {
        if (countryCode) {
            if (phoneOrEmail?.value?.slice(0, countryCode.length + 1) === `+${countryCode}`) {
                return phoneOrEmail?.value?.replace('\t', '');
            } else if (!phoneOrEmail?.value?.includes('+')) {
                return `+${countryCode}${phoneOrEmail?.value}`?.replace('\t', '');
            }
        }
        return phoneOrEmail?.value?.replace('\t', '');
    };

    const phoneChange = (e: any, match: any, char: any, dialCode: any) => {
        if (!phoneOrEmail?.isValid && error?.phone?.show) {
            setError(prev => ({ ...prev, phone: { show: false, message: '' } }));
        }

        if (dialCode && countryCode !== dialCode) {
            setCountryCode(dialCode);
        }
        let value = e?.target?.value || e;
        let isValid = match.isValid;

        if (value && match?.dialCode && value !== `+${match?.dialCode}`) {
            if (match?.isValid) {
                setError(prev => ({ ...prev, phone: { show: false, message: '' } }));
            } else {
                setError(prev => ({ ...prev, phone: { show: true, message: 'Please enter valid phone number' } }));
            }
        } else {
            setError(prev => ({ ...prev, phone: { show: false, message: '' } }));
        }

        if (char && isNaN(char)) {
            value = `${value.slice(dialCode.length + 1)}${char}`;
            isValid = false;
        }
        setPhoneOrEmail(prev => ({ ...prev, value: value, isValid: isValid }))
    };


    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: "column", width: "100vw", alignItems: "center" }}>
                <Title>Verification</Title>
                <Subtite>Enter your {checkInMode === 0 ? 'Phone Number or Email' : checkInMode === 1 ? 'Email' : 'Phone Number'}</Subtite>
                <div style={{ width: '90%', marginTop: '1rem' }}>
                    {
                        (checkInMode === 0 ?
                            (phoneOrEmail?.value ? isNaN(Number(phoneOrEmail?.value || undefined)) : true) :
                            checkInMode === 1) ?
                            <TextField
                                label={checkInMode === 0 ? "Email or Phone number" : "Email Address"}
                                variant="outlined"
                                name="email"
                                value={phoneOrEmail?.value || ''}
                                error={!phoneOrEmail?.isValid}
                                helperText={!phoneOrEmail?.isValid && "Please enter valid email address"}
                                onChange={(e: any) => {
                                    let formattedValue = removeWhiteSpace(e?.target?.value)
                                    setPhoneOrEmail({
                                        value: formattedValue,
                                        isValid: validEmail(formattedValue)
                                    });
                                }}
                                inputProps={{
                                    autoFocus: true
                                }}
                            />
                            :
                            <CountryPhone
                                inputProps={{
                                    type: 'text',
                                    autoFocus: true,
                                }}
                                editCountryCode={checkInMode === 0 ? true : false}
                                value={formatPhoneValue()}
                                onChange={phoneChange}
                                label="Enter Phone Number"
                                showError={error?.phone?.show}
                                errorText={error?.phone?.message}
                                className="phoneStyles"
                            />
                    }

                </div>
                <Button disabled={Boolean(!phoneOrEmail?.value || !phoneOrEmail?.isValid)} onClick={proceed} style={{ backgroundColor: "#2484DC", marginTop: "2.5rem" }}>
                    <CheckInText>Proceed</CheckInText>
                </Button>
            </div>
        </Container>
    );
}

export default Mobile;
