import styled from 'styled-components';

interface Props {
    Valid: boolean
}

export const Container = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`

export const OtpContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 5vh 0rem;
`
export const ButtonContainer = styled.div`
    position: relative;
    z-index: 100;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
`

export const Title = styled.h1`
    color: #2484DC;
    font-size: 2.5rem;
    width: 100%;
    font-family: Lato-Bold;
    text-align: center;
    margin-bottom: 0;
    @media (max-width: 380px) {
        font-size: 2rem;
    }
`

export const Subtite = styled.p`
    color: #000;
    font-size: 1.2rem;
    font-family: 'Urbanist', sans-serif !important;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem
`

export const CountryCode = styled.p`
    font-size: 1rem;
    font-family: Lato-Bold;
    text-align: center;
    color: ${(props: Props) => props.Valid ? '#000': '#ff0033'};
`

export const ErrorCode = styled.p`
    color: #ff0033;
    font-size: 1rem;
    font-family: Lato-Regular;
    text-align: center;

`

export const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
    margin-bottom: 1rem;
    border-bottom: 1px solid #AEAEB8;
    align-items: center;
    width: 100%;
    
`
export const RelativeContainer= styled.div`
    position: relative;
`

export const WordCount= styled.div`
    color: #9B9BA3;
    position: absolute;
    top: 25px;
    right: 5px;
    font-size: 0.8rem;
    font-family: Lato-Regular;
`