import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer, { UserState } from './User/User-reducers';
import visitorReducer, { VisitorState } from './Visitor/Visitor-reducers';
import doorReducer, { DoorState } from './DoorUnlock/DoorUnlock-reducers'
import SnackbarReducer, { SnackbarState } from './Snackbar/Snackbar-reducers';

export interface RootState {
    user: UserState;
    visitor: VisitorState;
    door: DoorState;
    snackbar: SnackbarState;
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['visitor']
}

const rootReducer = combineReducers({
    user: userReducer,
    visitor: visitorReducer,
    door: doorReducer,
    snackbar: SnackbarReducer,
})


export default persistReducer(persistConfig, rootReducer);