import styled from 'styled-components';

export const Button = styled.button`
    width: 14rem;
    height: 3.2rem;
    background-color: #FF9A5C;
    border-radius: 10px;
    border: 0px solid;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 0.2s;
    &:disabled{
        opacity: 0.6;
    }
    &:hover {
        -webkit-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.09);
        -moz-box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.09);
        box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.09);
        z-index: 10;
    }
    @media (max-height: 670px) {
        height: 2.6rem;
    }
`

export const CheckInText = styled.p`
    font-family: Poppins-Medium;
    color: #fff;
    font-size: 1rem;
    margin: 0rem;
    @media (max-height: 670px) {
        font-size: 0.8rem;
    }
`

export const LoadingText = styled.p`
    font-family: Poppins-Medium;
    color: #fff;
    font-size: 1.3rem;
    @media (max-height: 670px) {
        font-size: 1rem;
    }
    margin: 0rem;
`

export const Divider = styled.div`
    height: 2px;
    width: 100%;
    background: #000;
`

export const ErrorCode = styled.p`
    color: #ff0033;
    font-size: 1rem;
    font-family: Lato-Regular;
    text-align: center;
    margin-bottom: 2rem;
`