import React, { useState, useEffect, Fragment } from 'react';

import {
    Container,
    Title,
    Subtite,
    ButtonContainer
} from './DoorUnlocked.styles';

import Loader from '../../Components/Loader/Loader';
import { motion } from "framer-motion";
import Logo from '../../Components/Logo/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { DoorUnlock } from '../../Redux/DoorUnlock/DoorUnlock-actions';
import { ReactComponent as Tick } from '../../assets/images/Tick.svg';
import { ReactComponent as Remove } from '../../assets/images/remove.svg';
import { RootState } from '../../Redux/root-reducer';
import { useLocation } from 'react-router-dom';

var qs = require('qs');

const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
};

const DoorUnlocked = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const visitor = useSelector((state: RootState) => state?.visitor);
    const door = useSelector((state: RootState) => state?.door);

    const [display, setDisplay] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
        const orgId = parsedQuery?.orgId || parsedQuery?.organisationId;
        const doorId = parsedQuery?.doorId;

        if (!orgId || !doorId) {
            setTitle("Please Check-in/View Details from the link received if meeting is scheduled and try again.");
            setLoading(false);
            setDisplay(true);
            return;
        }

        let visitorId = visitor.visitorId;
        let meetingId = visitor.meetingId;
        if (!meetingId) {
            setMessage("Please Check-in/View Details from the link received if meeting is scheduled and try again.");
            setTitle('DOOR LOCKED');
            setLoading(false);
            setDisplay(true);
        } else {
            dispatch(DoorUnlock(orgId, doorId, meetingId));
        }

    }, [visitor]);

    useEffect(() => {
        if (door?.loading !== loading && visitor.meetingId) {
            setLoading(door?.loading);
            if (!door?.loading) {
                setMessage(door?.message);
                setTitle(door?.title);
                setDisplay(true);
            }
        }
        if (door?.error) {
            setMessage(door?.message);
            setTitle(door?.title);
        }
    }, [door, visitor.meetingId]);

    return (
        <Container>
            <div style={{ alignItems: "center", display: 'flex', marginTop: '2rem' }}>
                {visitor?.orgLogo && <Logo source={`data:image/png;base64,${visitor?.orgLogo}`} />}
            </div>
            <div style={{ display: 'flex', flexDirection: "column", width: "100vw", minHeight: '15rem', alignItems: "center", justifyContent: 'flex-end' }}>
                <>
                    <Title>{title}</Title>
                    {title === 'DOOR UNLOCKED' && (<Tick style={{ marginTop: '2rem' }} />)}
                    {title === 'DOOR LOCKED' && (<Remove style={{ marginTop: '2rem' }} />)}
                    {title == "DOOR LOCKED" && <Subtite style={{ marginBottom: '0' }}>ACCESS DENIED</Subtite>}
                    <Subtite style={{ fontSize: '1rem' }}>{message}</Subtite>
                </>
            </div>
            {loading && <Loader text="Unlocking Door" />}
        </Container>
    );
};

export default DoorUnlocked;
