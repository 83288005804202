import * as phoneValidator from 'libphonenumber-js';

export function validEmail(emailAdress: string) {
    let regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailAdress?.match(regexEmail)) {
        return true;
    } else {
        return false;
    }
}

export function validatePhoneNumbers(phone: string) {
    if (!phoneValidator.isValidNumber(phone)) {
        return false;
    } else {
        return true;
    }
}

export function base64ToFile(base64: string | null, filename: string) {
    const arr = base64?.split(',');
    const mime = arr[0]?.match(/:(.*?);/)?.[1] || 'image/jpeg';
    const bstr = atob(arr[1] || arr[0]);
    let n = bstr?.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr?.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function removeWhiteSpace(text: string) {
    return text?.replace(/\s+/g, '')
}