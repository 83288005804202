import { VisitorTypes } from './Visitor-types';
import { VisitorActions } from './Visitor-actions';


export interface VisitorState {
    loading: boolean,
    meetingId: number,
    visitorId: number,
    orgLogo: string,
    error: string | null    
}


const INITIAL_STATE  = {
    loading: false,
    meetingId: 0,
    visitorId: 0,
    orgLogo: '',
    error: null  
}


const VisitorReducer = (
    state: VisitorState = INITIAL_STATE,
    action: VisitorActions
) => {
    switch (action.type) {
        case VisitorTypes.VISITOR_INFO_START:
        case VisitorTypes.VISITOR_DELETE_START:
            return{
                ...state,
                loading: true,
                error: null
            }
        case VisitorTypes.VISITOR_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                meetingId: action.payload.meetingId,
                visitorId: action.payload.visitorId,
                orgLogo: action.payload.orgLogo,
                error: null
            }
        case VisitorTypes.VISITOR_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                meetingId: undefined,
                visitorId: undefined,
                orgLogo: '',
                error: null
            }
        case VisitorTypes.VISITOR_INFO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: 
        return state;
    }
};

export default VisitorReducer;