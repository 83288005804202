let spintlyConfig = {
    copyrightName: 'Spintly',
    salesEmail: 'sales@spintly.com',
    domain: 'spintly',
    theme: {
        name: 'spintly',
    },
    baseURL: ''
};

export default spintlyConfig;
