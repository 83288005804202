import React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

interface SnackbarProps {
    type?: string,
    message?: string,
    show?: boolean,
    hide?: any,
}

function Alert(props: any) {
    return <MuiAlert variant="filled" {...props} />;
}

export default function Snackbars({ type, message, show, hide }: SnackbarProps) {
    let snackBarAttributes:any = {};
    let icon;

    const switchSnackBarType = () => {
        switch (type) {
            case 'info':
                snackBarAttributes = {
                    backgroundColor: '#E4EFFB',
                    border: '2px solid #BDDBFD',
                    iconBackground: '#016BDF',
                };
                icon = <InfoOutlinedIcon fontSize="small" />;
                break;

            case 'warn':
            case 'warning':
                snackBarAttributes = {
                    backgroundColor: '#FCF3E4',
                    border: '2px solid #F9E3BE',
                    iconBackground: '#EF9433',
                };
                icon = <ReportProblemOutlinedIcon fontSize="small" />;
                break;

            case 'err':
            case 'error':
                snackBarAttributes = {
                    backgroundColor: '#FBECE8',
                    border: '2px solid #F2AD9D',
                    iconBackground: '#EA4E33',
                };
                icon = <ErrorOutlineOutlinedIcon fontSize="small" />;
                break;

            case 'success':
            default:
                snackBarAttributes = {
                    backgroundColor: '#EBF7EF',
                    border: '2px solid #BAF3CA',
                    iconBackground: '#60BE67',
                };
                icon = <CheckIcon fontSize="small" />;
                break;
        }
    };

    switchSnackBarType();

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            margin: '0 !important',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },

        snackbar: {
            borderRadius: '11px',
            backgroundColor: snackBarAttributes.backgroundColor,
            fontFamily: 'Urbanist',
            border: snackBarAttributes.border,
            fontSize: '13px',
            color: 'black',
            padding: '0.25rem',
        },

        iconContainer: {
            backgroundColor: snackBarAttributes.iconBackground,
            borderRadius: '9px',
            padding: '0.1rem 0.4rem',
            color: '#fff',
            alignItems: 'center',
        },

        actionContainer: {
            backgroundColor: 'white',
            justifyContent: 'center',
            paddingLeft: '0px',
            borderRadius: '8px',
            paddingTop: '0px',
            margin: '4px 8px 4px 12px',
        },

        icon: {
            fontSize: '14px',
        },

        messageContainer: {
            paddingRight: '3rem',
            textTransform: 'capitalize',
        },
    }));
    const classes = useStyles();

    const anchorOrigin: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
    };

    return (
        <span className={classes.root}>
            <Snackbar open={show} autoHideDuration={30000} onClose={hide} anchorOrigin={anchorOrigin}>
                <Alert
                    icon={icon}
                    onClose={hide}
                    classes={{
                        root: classes.snackbar,
                        icon: classes.iconContainer,
                        action: classes.actionContainer,
                        message: classes.messageContainer,
                    }}>
                    {message || type}
                </Alert>
            </Snackbar>
        </span>
    );
}
