import React from 'react'
import './Loader.css'

import {
    LoadingText
} from '../../Styles/index'

interface LoaderProps {
    text?: string
}

const Loader = ({ text } : LoaderProps) => {
    return (
        <div className="overlay">
            <div className="loader"></div>
            {text && (
                <div style={{
                    position: "absolute",
                    alignSelf: 'center',
                    bottom: '10%'
                }}><LoadingText>{text}</LoadingText></div>
            )}
        </div>
        
    )
}

export default Loader
