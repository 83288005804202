import React, { useState, useEffect } from 'react';

import {
    Container,
    Title,
    Content,
    TermsText,
    InnerContainer,
} from './Welcome.styles';

import {
    Button,
    CheckInText
} from '../../Styles'

// Animations
import { motion } from "framer-motion"

import {useSelector } from 'react-redux'

import { RootState } from '../../Redux/root-reducer';

interface WelcomeProps {
    setStep: (step: number) => void;
    nextStep: number;
}

const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
};

const Welcome: React.FC<WelcomeProps> = ({ setStep, nextStep }) => {

    const user = useSelector((state: RootState) => state?.user);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setDisplay(true);
        }, 500);
    }, []);

    return (
        <Container>
            <InnerContainer>
                <Content>
                    <motion.div
                        style={{ opacity: 0 }}
                        animate={display ? "open" : "closed"}
                        variants={variants}
                        transition={{ duration: 1.5 }}
                    >
                        <Content>
                            <Title>Hi, {user.visitorName}</Title>
                            <div style={{ width: "100%" }}>
                                <TermsText>
                                    Welcome to {user.organisationName}
                                </TermsText>
                            </div>
                        </Content>
                    </motion.div>
                </Content>
                <Button onClick={() => setStep(nextStep)} style={{ backgroundColor: "#2484DC", marginTop: "0.5rem" }}>
                    <CheckInText>
                        Proceed
                    </CheckInText>
                </Button>
            </InnerContainer>
        </Container>
    );
};


export default Welcome;
