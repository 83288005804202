import React from 'react'


// redux
import { connect } from 'react-redux'
import { UserState } from '../../Redux/User/User-reducers';

import './Logo.styles.css'


interface LogoProps {
    user: UserState,
    source?: string,
}

const Logo = ({ user, source }: LogoProps) => {
    return (
        <div>
            <img alt='Organisation Logo' src={`${source}`} className="logoImage" />
        </div>
    )
}


Logo.defaultProps = {
    source: ''
}

const mapStateToProps = (state: any) => ({
    user: state.user,
})

export default connect(mapStateToProps)(Logo)
