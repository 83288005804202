import axios from "axios";
import config from "../Configurations";
let NODE_ENV = process.env.REACT_APP_NODE_ENV;
const instance = axios.create({
  baseURL: config?.baseURL ? '' : `https://${(NODE_ENV === "production") ? '' : `${NODE_ENV}.`}saams.api.spintly.com/v2/visitorManagementV3`,
});

export const Api1 = axios.create({
  baseURL: config?.baseURL ? '' : `https://${(NODE_ENV === "production") ? '' : `${NODE_ENV}.`}saams.api.spintly.com/visitorManagementV3`,
});

export const ConfigApi = axios.create({
  baseURL: config?.baseURL ? '' : `https://${(NODE_ENV === "production") ? '' : `${NODE_ENV}.`}config.saams.api.spintly.com/callManagement`,
});

export default instance;
