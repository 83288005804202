import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`


export const Content = styled.div`
    display: flex; 
    align-items: center; 
    flex-direction: column;
    margin-bottom: 5rem;
`

export const WordCount= styled.div`
    color: #9B9BA3;
    position: absolute;
    top: 25px;
    right: 5px;
    font-size: 0.8rem;
    font-family: Lato-Regular;
`

export const RelativeContainer= styled.div`
    position: relative;
`
