import styled from 'styled-components';

export const Container = styled.div`
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`


export const ButtonContainer = styled.div`
    position: absolute;
    z-index: 100;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
`