export const colors = {
    green: '#24bc64',
    red: '#d9471a',
    danger: '#ea5734',
    primary: '#EC6333',
    yellow: '#dada00',
    magazineBrown: '#a49870',

    white: '#fff',
    black: '#1b1a1a',
    lighterPrimary: '#eef2f4',
    lightPrimary: '#d1dbe3',
    lightenPrimary: '#FCEFE5',
    bodyColor: '#f8f8f8',
    linkColor: '#e5e8ea',
    cardDivider: '#eff3f9',
    adminGray: '#dddfe1',

    darkGray: '#586976',
    text: '#1b1b1b',
    textPrimary: '#9dafbd',
    textSecondary: '#54617a',
    darkerGrey: '#242e42',
    paperCardShadow: '#d7dee365',
    adminPrimary: '#455a64',
    adminSecondry: '#abafb3',
    greyDarken: '#8C95AA',
    greyLight: '#F3F4F6',

    greyBorder: '#E9E9EA',

    // ////

    accent: '#ff6601',
    accent_light: '#ffefe5',

    menu: '#8d94aa',
    tabs: '#4f5463',
    textColor: '#8c95aa',

    bright_red: '#FF0000',
    bright_green: '#10B504',
    greyDark: '#81899c',
    greyPrimary: '#e0e0e0',

    primaryBackground: '#F7C8A2',
    blue: '#0011FF',
    textDarkBlue: '#0141c0',
};
