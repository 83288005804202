import styled from 'styled-components';

export const Container = styled.div`
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Footer = styled.footer`
    display: flex; 
    flex-direction: row;
    position: absolute;
    bottom: 3%;
    width: 15rem;
    justify-content: space-between;
    align-items: center;
`

export const FooterText = styled.p`
    font-family: Lato-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 23px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000;
    margin-block: 0;
`

export const Image = styled.img`
    width: 6rem;
    height: 2.5rem;
    object-fit: contain;
`

