import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { countries, zones } from 'moment-timezone/data/meta/latest.json'
import countryCodes from './countries.json';
import * as phoneValidator from 'libphonenumber-js';
import './countryPhoneStyles.scss';
/**
 * @description Function to get country code from the current timezone of the users system
 * @returns Object
 */
export function getCountryCode() {
    const timeZoneCityToCountry: any = {};

    Object.keys(zones).forEach((z) => {
        const cityArr = z.split('/');
        const city = cityArr[cityArr.length - 1];
        timeZoneCityToCountry[city] = countries[zones[z].countries[0]].abbr;
    });
    let userRegion;
    let userCity;
    let userCountry;
    let userTimeZone;

    if (Intl) {
        userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let tzArr = userTimeZone.split('/');
        userRegion = tzArr[0];
        userCity = tzArr[tzArr.length - 1];
        userCountry = timeZoneCityToCountry[userCity];
        if (userCity === 'Calcutta' && !userCountry) {
            userCountry = 'IN';
        }
    }
    let countryCode = '+91';
    if (userCountry) {
        for (let i = 0; i < countryCodes.length; i++) {
            if (countryCodes[i].alpha2Code.toLowerCase() === userCountry.toLowerCase()) {
                countryCode = `+${countryCodes[i].callingCodes[0] ? countryCodes[i].callingCodes[0] : '91'}`;
                break;
            }
        }
    }
    return { countryCode: countryCode, userCountry: userCountry ? userCountry.toLowerCase() : 'in' };
}

export function validatePhoneNumbers(phone: any) {
    if (!phoneValidator.isValidNumber(phone)) {
        return false;
    } else {
        return true;
    }
}

export function isValidPhoneNo(number: any) {
    let isValid = validatePhoneNumbers(`${number?.includes('+') ? '' : '+'}${number}`);
    return !!isValid;
}

let isValid: any;
isValid = true

export default function CountryPhone({
    value,
    onChange,
    placeholder,
    label,
    className,
    showError,
    errorText,
    updateCTA,
    countryCode,
    editCountryCode = false,
    ...other
}: any) {
    useEffect(() => {
        if (value) {
            isValid = isValidPhoneNo(value);
        } else {
            isValid = true;
        }

        if (onChange) {
            onChange(value, { isValid, dialCode: countryCode ? countryCode?.replace('+', '') : getCountryCode()?.countryCode?.replace('+', '') });
        }
    }, []);

    const hChange = (number: any, isoMatch: any, event: any) => {
        isValid = isValidPhoneNo(number);
        if (onChange) {
            let latestChar = event?.nativeEvent?.data
            onChange(number === '' ? '' : `+${number}`, {
                ...isoMatch,
                isValid,
            },
                latestChar ?
                    latestChar?.length > 1 ? latestChar[latestChar?.length - 1] : latestChar // Instead of directly using nativeEvent.data this is done cuz, mobile browser returned entire value instead of latest char
                    : null,
                isoMatch.dialCode);
        }
        if (showError && !isValid) {
            updateCTA && updateCTA(true);
        } else updateCTA && updateCTA(false);
    };

    return (
        <>
            <div className={'countryPhone ' + className}>
                <PhoneInput
                    dropdownStyle={{ fontFamily: 'Urbanist', overflowX: 'hidden' }}
                    searchStyle={{ marginLeft: '-3px' }}
                    enableSearch={true}
                    disableSearchIcon={true}
                    autoFormat={false}
                    countryCodeEditable={editCountryCode}
                    placeholder={label || placeholder}
                    country={getCountryCode().userCountry}
                    value={value || ''}
                    onChange={(number, isoMatch, event) => {
                        hChange(number, isoMatch, event)
                    }}
                    {...other}
                />
            </div>
            {((showError && !isValid) || errorText) && (
                <small className="errorMessage">{errorText || 'Please enter valid phone number'}</small>
            )}
        </>
    );
}

