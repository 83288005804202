import React from 'react';
import { Container, Label, TextArea } from './BorderedTextArea.styles';

interface Props {
    value: string;
    onSetVal: (value: string)=>void;
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    minHeight?: string | number
}

const BorderedTextArea = ({placeholder="", value, onSetVal, disabled, label, minHeight }:Props) => {
    return (
        <Container>
            {
                label && <Label>{label}</Label>
            }
            <TextArea 
                style={{ minHeight: minHeight || 'auto'}}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onSetVal(event.target.value)}
            ></TextArea>
        </Container>
    );
}

export default BorderedTextArea;