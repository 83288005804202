import React, { useEffect, useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Api1 } from "../../axios/axiosConfig";
import { useHistory, useLocation } from "react-router-dom";

const qs = require('qs');

const VisitorPass = () => {

  const location = useLocation();

  const [pdf, setPdf] = useState('')
  const [error, setError] = useState()
  const history = useHistory();

  const meetingId: number = qs.parse(location.search, { ignoreQueryPrefix: true }).mId
  const accessKey: string = qs.parse(location.search, { ignoreQueryPrefix: true }).accessKey

  useEffect(() => {
    if (meetingId) {
      let header = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      Api1.post(`/v1/visitorPass/meeting/${meetingId}/pdf`, { accessKey: accessKey }, header).then((res: any) => {
        if (res.data?.type === 'success') {
          let linkSource = `data:application/pdf;base64,${res?.data?.message}`;
          setPdf(linkSource)
        } else {
          setError(res?.data?.message)
        }
      }).catch(err => {
        if (err?.response?.data?.message?.displayMessage == "Email Link Has Expired") {
          history.push("/linkExpired")
        } else {
          setError(err?.response?.data?.message?.displayMessage)
        }
      })
    }
  }, [meetingId, accessKey])


  return (
    <div style={{ width: '100%' }}>
      <div>
        {!error ?
          <Document loading="Almost done fetching" noData="Please wait while we fetch your visitor's pass" key={meetingId} file={pdf}> <Page pageNumber={1} /> </Document>
          :
          <div style={{ display: 'flex', alignItems: 'center', minHeight: '95vh' }}>
            <h1 style={{ textAlign: 'center', color: 'red', fontSize: "2rem" }}>{error}</h1>
          </div>
        }
      </div>
    </div>
  )
};


export default VisitorPass;
