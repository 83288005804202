import React, { useState, useEffect, Fragment } from 'react';
import Resizer from 'react-image-file-resizer';
import {  useDispatch, useSelector } from 'react-redux';
import { savePhotoID } from '../../Redux/User/User-actions';

import { ReactComponent as Avatar } from '../../assets/images/Avatar.svg';
import { ReactComponent as Camera } from '../../assets/images/Camera.svg';
import {
    Container,
    Content,
    UploadText,
    RetakeContainer,
    RetakeText,
    CameraInput
} from './Profile.styles';
import {
    Button,
    CheckInText
} from '../../Styles';

import { RootState } from '../../Redux/root-reducer';
import { ErrorCode } from '../LoginForm/LoginForm.styles';

interface ProfileProps {
    setStep: (step: number) => void;
    nextStep:number
}


const Profile: React.FC<ProfileProps> = ({ setStep,nextStep }) => {

    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);

    const [image, setImage] = useState<any>('');
    const [error, setError] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (user.visitorPhoto !== '') {
            if (user.visitorPhoto.match(/^data:image\//)) {
                setImage(user.visitorPhoto);
            } else {
                setImage(`data:image/png;base64,${user.visitorPhoto}`);
            }
        }
    }, [user]);

    const imageReader = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setIsDisabled(true);

            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    user.imageData.height,
                    user.imageData.width,
                    user.imageData.format,
                    user.imageData.quality,
                    0,
                    uri => {
                        setImage(uri);
                        setIsDisabled(false);
                    },
                    'base64'
                );
            } catch (err) {
                console.log(err);
            }

        }
    };

    const Proceed = () => {
        if (image === '') {
            setError('Please upload an image');
            setTimeout(() => setError(''), 2000);
            return;
        }
        if (image) {
            dispatch(savePhotoID(image));
            setStep(nextStep)
        }
    };

    return (
        <Container>
            <Content>
                {image === '' ? (
                    <div style={{ position: 'relative', width: '8.5rem', height: '8.5rem' }}>
                        <Avatar style={{ width: '8.5rem', height: '8.5rem' }} />
                        <Camera style={{ position: 'absolute', bottom: 0, right: 0, height: '3.5rem', width: '3.5rem' }} />
                        <CameraInput type="file" accept="image/*" capture="user" onChange={imageReader} />
                    </div>
                ) : (
                    <Fragment>
                        <div style={{ position: 'relative', width: '8.5rem', height: '8.5rem' }}>
                            <div style={{ width: 130, height: 129, borderRadius: 130, overflow: 'hidden' }}>
                                <img alt='Visitor' src={image} style={{ width: '100%', height: 'auto', borderRadius: 130, objectFit: 'cover' }} />
                            </div>
                            <Camera style={{ position: 'absolute', bottom: 0, right: 0, height: '3.5rem', width: '3.5rem' }} />
                            <CameraInput type="file" accept="image/*" capture="user" onChange={imageReader} />
                        </div>
                        <UploadText>Upload Profile Picture</UploadText>
                        <RetakeContainer>
                            <CameraInput type="file" accept="image/*" capture="user" onChange={imageReader} />
                            <RetakeText>Retake</RetakeText>
                        </RetakeContainer>
                    </Fragment>
                )}
                <ErrorCode>{error}</ErrorCode>
                <Button disabled={isDisabled} onClick={Proceed} style={{ backgroundColor: isDisabled ? '#808080' : '#2484DC', marginTop: '2.5rem' }}>
                    <CheckInText>
                        Proceed
                    </CheckInText>
                </Button>
            </Content>
        </Container>
    );
};

export default Profile;
