import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`

export const OtpContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 5vh 0rem;
`
export const ButtonContainer = styled.div`
    position: relative;
    z-index: 100;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    
`

export const Title = styled.h1`
    color: #2484DC;
    font-size: 2.5rem;
    width: 100%;
    font-family: Lato-Bold;
    text-align: center;
    margin-bottom: 0;
    @media (max-width: 380px) {
        font-size: 2rem;
    }
    @media (max-height: 670px) {
        font-size: 2rem;
    }
`

export const Subtite = styled.p`
    color: #000;
    font-size: 0.8rem;
    font-family: Lato-Regular;
    text-align: center;
    @media (max-width: 380px) {
        font-size: 0.7rem;
    }
`

export const CountryCode = styled.p`
    color: #000;
    font-size: 1rem;
    font-family: Lato-Bold;
    text-align: center;
`

export const InputContainer = styled.div`
    display: flex;
    justify-content: space-around;
    position: relative;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #AEAEB8;
    align-items: center;
    width: 350px;
    @media (max-width: 380px) {
        width: 320px;
    }
    @media (max-width: 320px) {
        width: 100%;
    }
    @media (max-width: 300px) {
        width: 100%;
    }
    
`