import styled from 'styled-components';

interface Props {
    image: string
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    @media (max-height: 380px) {
        overflow-y: auto;
    }
    @media (max-height: 830px) {
        justify-content: flex-start;
    }
`


export const Content = styled.div`
    display: flex; 
    align-items: center; 
    flex-direction: column;
`

export const CameraInput = styled.input`
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 2rem;
    background: transparent;
    position: absolute;
    color: transparent;
    border: 0px solid #ccc !important;
    background-color: transparent !important;
    opacity: 0;
    bottom: 0rem;
    right: 0rem;
`

export const UploadText = styled.p`
    font-family: Lato-Regular;
    color: #9B9BA3;
    text-align: center;
    font-size: 1.2rem;
    margin: 1rem 0;
`

export const RetakeContainer = styled.div`
    height: 3rem;
    width: 8rem;
    position: relative;
    background: #9B9BA3;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    @media (max-height: 670px) {
        height: 2rem;
        margin-top: 0.5rem;
        margin-bottom: 0rem;
    }
    @media (max-height: 830px) {
        height: 3rem;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
`

export const RetakeText = styled.p`
    text-align: center;
    color: #fff;
    font-family: Lato-Regular;
    margin: 0 !important;
    margin-top: 0.2rem;
    font-size: 1rem;
    @media (max-height: 670px) {
        font-size: 0.8rem;
    }
`

export const BackImage = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    background-image: url(${(props: Props) => props.image});
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`