import React, { useState, useEffect } from 'react';

import {
    Container,
    ErrorCode,
    Subtite
} from './LoginForm.styles';

import {
    Button,
    CheckInText
} from '../../Styles';


// redux
import { useDispatch, useSelector } from 'react-redux';
import { saveVisitorData } from '../../Redux/User/User-actions';

import { RootState } from '../../Redux/root-reducer';
import TimePicker from '../../Components/TimePicker/TimePicker';
import './loginFormStyles.scss'
import CountryPhone, { isValidPhoneNo } from '../../Components/CountryPhone/CountryPhone';
import TextField from '../../Components/TextField/Textfield';
import AutoSelectDD from '../../Components/CustomDropdown/AutoSelectDD';
import moment from 'moment-timezone';
import { validEmail } from '../../utils';
import { IconButton } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

interface LoginFormProps {
    setStep: (step: number) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ setStep }) => {

    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state?.user);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState({ showError: false, isValid: true });
    const [phoneNumber, setPhoneNumber] = useState('');
    const [personToMeet, setPersonToMeet] = useState<number | null>(null);
    const [purpose, setPurpose] = useState('');
    const [disableProceed, setDisableProceed] = useState(false);
    const [formStep, setFormStep] = useState(0);
    const [endTime, setEndTime] = useState(null);
    const [endTimeValid, setEndTimeValid] = useState(true);
    const startTime = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [visitorComment, setVisitorComment] = useState("");
    const [countryCode, setCountryCode] = useState<string>('91');

    useEffect(() => {
        setEmail(user.email);
        setName(user.visitorName);
        setPhoneNumber(user.phone);
        setPersonToMeet(user?.toMeet?.id);
        setEndTime(user?.endTime ? typeof user?.endTime === 'string' ? moment(user?.endTime, 'hh:mm:ss A').toDate() : user?.endTime : null);
        setPurpose(user?.meetingPurpose);
        setVisitorComment(user?.visitorComments);
    }, [user]);

    useEffect(() => {
        if (user?.mobileNumberVerified && email && !validEmail(email)) {
            setEmailValid(false)
        } else {
            setEmailValid(true)
        }

        if (endTime && endTime <= startTime) {
            setEndTimeValid(false)
        } else {
            setEndTimeValid(true)
        }
    }, [user, email, endTime])

    useEffect(() => {
        if (formStep === 0 && !name?.length) {
            setDisableProceed(true)
        } else if (formStep === 1 &&
            (
                (user?.personToMeetEnabled && !personToMeet) ||
                (user?.purposeOfMeetingEnabled && !purpose) ||
                (user?.endTimeEnabled && !endTime) ||
                (user?.endTimeEnabled && !endTimeValid) ||
                (user?.visitorEmailEnabled && user?.visitorPhoneEnabled && (
                    (user?.mobileNumberVerified ? !email : !phoneNumber) ||
                    (user?.mobileNumberVerified ? !emailValid : !phoneValid?.isValid)
                ))
            )
        ) {
            setDisableProceed(true)
        } else {
            setDisableProceed(false)
        }
    }, [user, formStep, name, email, phoneNumber, personToMeet, purpose, endTime, emailValid, phoneValid, endTimeValid])

    const proceed = () => {
        if (formStep === 0 && name.length) {
            setFormStep(1);
        } else {
            let toUpdate: any = {};
            if (name) {
                toUpdate["visitorName"] = name;
            }

            if (email) {
                toUpdate["email"] = email;
            }

            if (phoneNumber) {
                toUpdate["phone"] = phoneNumber;
            }

            if (purpose) {
                toUpdate["meetingPurpose"] = purpose;
            }

            toUpdate["startTime"] = startTime;
            toUpdate["endTime"] = endTime;

            if (personToMeet) {
                toUpdate["toMeet"] = user?.personsToMeet?.find(elem => elem.id == personToMeet);
            }

            if (visitorComment) {
                toUpdate["visitorComments"] = visitorComment;
            }

            dispatch(saveVisitorData(toUpdate));
            setStep(4)
        }

    };


    const hTimeChange = (event: any) => {
        let value = event.target.value;
        setEndTime(value);
    }


    const formatPhoneValue = () => {
        if (countryCode) {
            if (phoneNumber.slice(0, countryCode.length + 1) === `+${countryCode}`) {
                return phoneNumber?.replace('\t', '');
            } else if (!phoneNumber?.includes('+')) {
                return `+${countryCode}${phoneNumber}`?.replace('\t', '');
            }
        }
        return phoneNumber?.replace('\t', '');
    };

    const phoneChange = (e: any, match: any, char: any, dialCode: any) => {
        if (dialCode && countryCode !== dialCode) {
            setCountryCode(dialCode);
        }
        let value = e?.target?.value || e;
        let isValid = match.isValid;

        if (value && match?.dialCode && value !== `+${match?.dialCode}`) {
            if (match?.isValid) {
                setPhoneValid(prev => ({ ...prev, showError: false }));
            } else {
                setPhoneValid(prev => ({ ...prev, showError: true }));
            }
        } else {
            setPhoneValid(prev => ({ ...prev, showError: false }));
        }

        if (char && isNaN(char)) {
            value = value.slice(dialCode.length + 1) + char;
            isValid = false;
        }
        setPhoneNumber(value)
        setPhoneValid(prev => ({ ...prev, isValid: isValid }));
    };

    return (
        <Container>
            {formStep > 0 && <IconButton style={{ position: 'absolute', top: '10px', left: '10px' }} onClick={() => { setFormStep(formStep - 1) }}><KeyboardBackspaceIcon color={'black'} /></IconButton>}
            <div style={{ display: 'flex', flexDirection: "column", width: "95vw", alignItems: "center" }}>
                {formStep === 0 ? (
                    <TextField
                        style={{ marginTop: '1rem' }}
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={name || ''}
                        onChange={(event: any) => {
                            let value = event?.target?.value?.trimStart()
                            setName(value);
                        }}
                    />
                ) :
                    <>
                        <Subtite>Check-in time: {startTime?.format("hh:mm A (z)")}</Subtite>
                        <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column', width: '95%', marginTop: '1rem', marginBottom: '1rem' }}>

                            {(user.visitorEmailEnabled && user?.visitorPhoneEnabled) && (
                                user?.mobileNumberVerified ?
                                    <div>
                                        <TextField
                                            label="Email Address"
                                            variant="outlined"
                                            name="email"
                                            type="email"
                                            value={email || ''}
                                            onChange={(event: any) => {
                                                setEmail(event.target.value);
                                                setEmailValid(true);
                                            }}
                                            error={!emailValid}
                                            helperText={!emailValid && "Please enter valid email address"}
                                        />
                                    </div> :
                                    <div>
                                        <CountryPhone
                                            disabled={false}
                                            value={formatPhoneValue()}
                                            onChange={phoneChange}
                                            label="Enter Phone Number"
                                            showError={phoneValid?.showError}
                                            errorText={phoneValid?.showError && "Please enter valid phone number"}
                                        />
                                    </div>

                            )}

                            {user?.purposeOfMeetingEnabled && (
                                <AutoSelectDD
                                    selectNone={true}
                                    text="Purpose of the meeting"
                                    dropDownOptions={user?.meetingPurposes || []}
                                    name="meetingPurpose"
                                    value={purpose}
                                    onChange={(e: any) => {
                                        setPurpose(e.target.value)
                                    }}
                                />
                            )}
                            {user?.endTimeEnabled &&
                                <div>
                                    <TimePicker
                                        disableInput={true}
                                        ampm={true}
                                        label="End time"
                                        value={endTime}
                                        name="start"
                                        onChange={hTimeChange}
                                    />
                                    {!endTimeValid && <ErrorCode style={{ marginBottom: '0' }}>End time should be greater then check-in time</ErrorCode>}
                                </div>
                            }
                            {user.personToMeetEnabled && (
                                <AutoSelectDD
                                    selectNone={true}
                                    text="Person to meet"
                                    dropDownOptions={user?.personsToMeet || []}
                                    name="personToMeet"
                                    value={personToMeet}
                                    onChange={(e: any) => {
                                        setPersonToMeet(e.target.value)
                                    }}
                                />
                            )}
                            {user.additionalInfoEnabled && user.visitorAdditionalInfo && (
                                <div style={{ position: 'relative' }}>
                                    <p style={{ position: 'absolute', zIndex: 10, right: '5px', top: '3px', color: '#8d94aa', fontSize: '0.7rem', marginBlock: '0' }}>{120 - (visitorComment?.length || 0)}</p>
                                    <TextField
                                        multiline
                                        rows={3}
                                        label="Visitor Comment"
                                        variant="outlined"
                                        name="comment"
                                        value={visitorComment || ''}
                                        onChange={(event: any) => {
                                            let value = event?.target?.value?.trimStart()
                                            if (value?.length <= 120) {
                                                setVisitorComment(value)
                                            }
                                        }}
                                        wordLimit={120}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                }
                <Button disabled={disableProceed} onClick={() => { proceed() }} style={{ backgroundColor: "#2484DC", marginTop: "1.5rem", marginBottom: '2rem' }}>
                    <CheckInText>
                        PROCEED
                    </CheckInText>
                </Button>
            </div>
        </Container>
    );
};

export default LoginForm;
