import React, { Component, Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom';
import { isMobile, BrowserView, MobileView } from 'react-device-detect';

import "./App.css";

// redux
import { persistor, store } from './Redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';



// import screens
import Splash from "./Screens/Splash/Splash"
import Routes from './Routes/Routes';
import { Title } from './Screens/Mobile/Mobile.styles';

import defaultLogo from './assets/images/spintly_icon.png'
import config from './Configurations';

import version from './version';

const setFavicon = (favicon: any) => {
  const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement || document.createElement('link');
  link.rel = 'icon';
  link.href = favicon || defaultLogo;
  document.head.appendChild(link);
}
interface AppState {
  gateLifted: boolean;
}

interface AppProps {

}


export class App extends Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props)
    this.state = {
      gateLifted: false,
    }
  }

  componentDidMount(): void {
    const theme = config?.theme?.name;
    import(`./assets/images/${theme}_icon.png`)
      .then((module) => {
        setFavicon(module.default);
      })
      .catch(() => {
        setFavicon(defaultLogo);
      });
  }

  onBeforeLift = () => {
    this.setState({ gateLifted: true })
  }
  render() {
    const { gateLifted } = this.state
    let location = window.location?.href?.split('/')[3]
    return (
      <Provider store={store}>
        <BrowserRouter>
          <PersistGate persistor={persistor} onBeforeLift={this.onBeforeLift}>
            {!gateLifted ? (
              <Splash />
            ) : (
              <Fragment>
                <BrowserView>
                  <div style={{ flex: 1, justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <Title>Please open the link on your smartphone</Title>
                  </div>
                </BrowserView>
                <MobileView style={{ minHeight: '95vh' }}>
                  {isMobile && (
                    <Routes />
                  )}
                </MobileView>
                {!location?.includes('visitorPass') && (
                  <p className='version'>Version : {version}</p>
                )}
              </Fragment>
            )}
          </PersistGate>
        </BrowserRouter>
      </Provider>
    )
  }
}






export default App
