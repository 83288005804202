import React from 'react'

import {
    Container,
    ButtonContainer,
    Title,
} from '../Mobile/Mobile.styles';
import { TermsText } from '../Terms/Terms.styles';
import defaultFavicon from '../../assets/images/spintly.png'
import { Footer, FooterText, Image } from '../Splash/Splash.styles';
import config from '../../Configurations';
let theme = config?.theme?.name;
let logo: any = null;

import(`../../assets/images/${theme}.png`)
    .then((module) => {
        logo = module.default;
    })
    .catch(() => {
        logo = defaultFavicon;
    });


const Home = () => {
    return (
        <Container>
            <ButtonContainer>
                <div style={{ display: 'flex', flexDirection: "column", width: "100vw", alignItems: "center" }}>
                    <Title>Welcome to {config.copyrightName}'s Visitor Management</Title>
                    <TermsText style={{ textAlign: "center", maxWidth: 500, lineHeight: 2 }}>
                        To access all the features you are required to scan the QR code
                        at the organisation using {config.copyrightName}'s visitor management.
                        For further details please contact
                        <a href={`mailto:${config.salesEmail}`}> {config.salesEmail}</a>
                    </TermsText>
                </div>
            </ButtonContainer>
            <Footer>
                <FooterText> Powered by  :</FooterText>
                <Image src={logo} />
            </Footer>
        </Container>
    )
}

export default Home

