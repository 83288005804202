import { createStore, applyMiddleware} from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';

const initialState:any = {}

const middlewares = [thunk]

// if(process.env.NODE_ENV === 'development'){
    
// }


export const store = createStore(
    rootReducer, 
    initialState, 
   applyMiddleware(...middlewares)
);


export const persistor = persistStore(store);

export default { persistor, store };

// composeWithDevTools(applyMiddleware(...middlewares))