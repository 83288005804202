import React, { useState, useEffect } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { saveTermsCondition } from '../../Redux/User/User-actions';
import { ReactComponent as Checkmark } from '../../assets/images/Checkmark.svg';
import {
    Container,
    Title,
    Content,
    TermsContainer,
    TermsText,
    TermsAccept,
    Box,
    AcceptText
} from './Terms.styles';
import {
    Button,
    CheckInText
} from '../../Styles';
import { ErrorCode } from '../LoginForm/LoginForm.styles';
import { RootState } from '../../Redux/root-reducer';

interface TermsProps {
    setStep: (step: number) => void;
    nextStep: number;
}

const Terms: React.FC<TermsProps> = ({ setStep, nextStep }) => {

    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state?.user);

    const [accepted, setAccepted] = useState(false);
    const [error, setError] = useState('');
    const [terms, setTerms] = useState<string[]>([]);

    useEffect(() => {

        if (user.terms) {
            const str = user.terms;
            const buff = Buffer.from(str, 'base64');
            const base64ToStringNew = buff.toString('utf8');
            const arrayOfStrings = base64ToStringNew.split('\n');
            setTerms(arrayOfStrings);
        } else {
            setTerms(['']);
        }
    }, [user]);

    const Proceed = () => {
        if (!accepted) {
            setError('Please accept the terms and agreement');
            setTimeout(() => setError(''), 2000);
            return;
        }
        dispatch(saveTermsCondition(accepted));
        setStep(nextStep)
    };

    return (
        <Container>
            <Content>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', alignItems: 'center' }}>
                    <Title>Terms & Conditions</Title>
                    <TermsContainer>
                        {terms.map((term, index) => (
                            <TermsText key={index}>
                                {term}
                            </TermsText>
                        ))}
                    </TermsContainer>
                    <TermsAccept>
                        <Box
                            onClick={() => setAccepted(!accepted)}
                            style={accepted ? { backgroundColor: '#000' } : { backgroundColor: '#fff' }}
                        >
                            {accepted && (
                                <Checkmark style={{ width: '1rem' }} />
                            )}
                        </Box>
                        <AcceptText>I accept the terms & conditions</AcceptText>
                    </TermsAccept>
                    <ErrorCode>{error}</ErrorCode>
                    <Button
                        onClick={Proceed}
                        style={{ backgroundColor: '#2484DC', marginTop: '0rem' }}
                    >
                        <CheckInText>
                            Proceed
                        </CheckInText>
                    </Button>
                </div>
            </Content>
        </Container>
    );
};

export default Terms;
