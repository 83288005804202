import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-height: 380px) {
        overflow-y: auto;
    }
    @media (max-height: 830px) {
        overflow-y: auto;
    }
`




export const Title = styled.h1`
    color: #2484DC;
    font-size: 1.7rem;
    width: 100%;
    font-family: Lato-Bold;
    text-align: center;
    margin-bottom: 1rem;
    padding: 0 1rem;
`

export const Content = styled.div`
    display: flex; 
    align-items: center; 
    flex-direction: column;
    margin-bottom: 5rem;
`

export const TermsContainer = styled.div`
    background-color: white;
    box-sizing: border-box;
    max-height: 20rem;
    letter-spacing: 0.1rem;
    line-height: 1.2rem;
    overflow-y: auto;
    position: relative;
    z-index: 1000;
    width: 350px !important;
    @media (max-width: 350px) {
        width: 270px !important;
    }
    @media (max-width: 830px) {
        max-height: 16rem;
    }
`

export const TermsText = styled.p`
    font-family: Popins-Regular;
    color: #5F5F5F;
    font-size: 1rem;
    text-align: justify;
    text-justify: inter-word;
    @media (max-width: 830px) {
        font-size: 0.8rem;
    }
`

export const TermsAccept = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`

export const Box = styled.div`
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    background: #fff;
    margin: 0 0.8rem;
`

export const AcceptText = styled.p`
    font-family: Lato-Regular;
    color: #000;
    font-size: 1.1rem;
    @media (max-height: 670px) {
        font-size: 0.8rem;
    }
`