import { DoorTypes } from './DoorUnlock-types';
import { DoorActions } from './DoorUnlock-actions';


export interface DoorState {
    loading: boolean,
    message: string,
    title: string,
    error: string | null    
}


const INITIAL_STATE  = {
    loading: false,
    message: '',
    title: '',
    error: null  
}


const doorReducer = (
    state: DoorState = INITIAL_STATE,
    action: DoorActions
) => {
    switch (action.type) {
        case DoorTypes.DOOR_UNLOCK_START:
            return{
                ...state,
                loading: true,
                error: null
            }
        case DoorTypes.DOOR_UNLOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                title: action.payload.title,
                error: null
            }
        
        case DoorTypes.DOOR_UNLOCK_FAIL:
            return {
                ...state,
                loading: false,
                message: action.payload.message,
                title: action.payload.title,
                error: '404 could not access route'
            }
        default: 
        return state;
    }
};

export default doorReducer;