import { SnackbarTypes } from './Snackbar-types';



export interface SnackbarAction {
  type: SnackbarTypes,
  payload?: string | boolean | any
}

export const SetNotify = (data: {
  show?: boolean,
  message?: string,
  type?: string,
}) => ({
  type: SnackbarTypes.SET_NOTIFY,
  payload: data
})



