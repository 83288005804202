export enum UserTypes {
    REGISTERUSER_START = "REGISTERUSER_START",
    REGISTERUSER_PHONE_SUCCESS = "REGISTERUSER_PHONE_SUCCESS",
    REGISTERUSER_EMAIL_SUCCESS = "REGISTERUSER_EMAIL_SUCCESS",
    REGISTERUSER_FAIL = "REGISTERUSER_FAIL",

    LINK_START = "LINK_START",
    LINK_SUCCESS = "LINK_SUCCESS",
    LINK_SUCCESS_UPDATE_TERMS = "LINK_SUCCESS_UPDATE_TERMS",
    LINK_FAIL = "LINK_FAIL",

    EMAIL_LINK_START = "EMAIL_LINK_START",
    EMAIL_LINK_SUCCESS = "EMAIL_LINK_SUCCESS",
    EMAIL_LINK_FAIL = "EMAIL_LINK_FAIL",

    OTP_START = "OTP_START",
    OTP_SUCCESS = "OTP_SUCCESS",
    OTP_FAIL = "OTP_FAIL",

    REQUEST_START = "REQUEST_START",
    REQUEST_SUCCESS = "REQUEST_SUCCESS",
    REQUEST_FAIL = "REQUEST_FAIL",

    USER_PHOTOID_SUCCESS = "USER_PHOTOID_SUCCESS",

    USER_CHECKOUT_START = "USER_CHECKOUT_START",
    USER_CHECKOUT_SUCCESS = "USER_CHECKOUT_SUCCESS",
    USER_CHECKOUT_FAIL = "USER_CHECKOUT_FAIL",

    LOGIN_TYPE = "LOGIN_TYPE",

    SAVE_TERMS_CONDITION = "SAVE_TERMS_CONDITION",

    UPDATE_EMAIL_LINK_SUCCESS = "UPDATE_EMAIL_LINK_SUCCESS",

    UPDATE_COMMENT = "UPDATE_COMMENT",

    SET_VISITOR_DATA = "SET_VISITOR_DATA",

    SET_LOADER = "SET_LOADER",
}