import React from "react";
import styled from 'styled-components';

const Container = styled.div`
    min-height: 97vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    justify-content: center;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`

const Card = styled.div`
    display: flex;
    width: 85vw;
    justify-content: center;
    background: white;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: 30rem;
    border-radius: 1rem;
    box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 25%);
`


const LinkExpired = () => {

    return (
        <Container>
            <Card>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="141"
                    height="162"
                    fill="none"
                    viewBox="0 0 141 162"
                >
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M15.5 30L8 23M20.776 26.626l-.05-10.259M12.877 36.623l-10.256-.24"
                    ></path>
                    <g filter="url(#filter0_d_11028_14094)">
                        <circle cx="63" cy="91" r="58" fill="#FCA387"></circle>
                    </g>
                    <path
                        fill="#fff"
                        stroke="#000"
                        strokeWidth="2"
                        d="M19 43a9 9 0 019-9h81v91.624c-30.397 34.489-72.75 23.853-90 1.537V43z"
                    ></path>
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M41 61c3.238-.163 4.63-.888 6.5-3"
                    ></path>
                    <path
                        stroke="#FCA387"
                        strokeLinecap="round"
                        strokeOpacity="0.12"
                        strokeWidth="6"
                        d="M29.5 107.5H100"
                    ></path>
                    <path
                        stroke="#FCA387"
                        strokeLinecap="round"
                        strokeOpacity="0.1"
                        strokeWidth="6"
                        d="M29 119h70.5"
                    ></path>
                    <path
                        stroke="#FCA387"
                        strokeLinecap="round"
                        strokeOpacity="0.12"
                        strokeWidth="6"
                        d="M29 96h70.5"
                    ></path>
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M98 35c0 10.709.001 11.682 11 10.709"
                    ></path>
                    <path
                        stroke="#fff"
                        strokeWidth="8"
                        d="M24 122v6.745C47 154 86 149.5 104 127.059V122"
                    ></path>
                    <path
                        fill="#fff"
                        d="M111.781 0H154.614V31H111.781z"
                        transform="rotate(47.296 111.781 0)"
                    ></path>
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M100 34l9 10"
                    ></path>
                    <circle cx="46" cy="65" r="2" fill="#030303"></circle>
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M87 61c-3.238-.163-4.63-.888-6.5-3"
                    ></path>
                    <circle
                        cx="2"
                        cy="2"
                        r="2"
                        fill="#030303"
                        transform="matrix(-1 0 0 1 84 63)"
                    ></circle>
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="M57 75.875c3.5-4.5 11-4.5 14 0"
                    ></path>
                    <ellipse
                        cx="44.5"
                        cy="69.5"
                        fill="#FCA387"
                        fillOpacity="0.2"
                        rx="3.5"
                        ry="1.5"
                    ></ellipse>
                    <ellipse
                        cx="83.5"
                        cy="69.5"
                        fill="#FCA387"
                        fillOpacity="0.2"
                        rx="3.5"
                        ry="1.5"
                    ></ellipse>
                    <defs>
                        <filter
                            id="filter0_d_11028_14094"
                            width="126"
                            height="129"
                            x="0"
                            y="33"
                            colorInterpolationFilters="sRGB"
                            filterUnits="userSpaceOnUse"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                            <feColorMatrix
                                in="SourceAlpha"
                                result="hardAlpha"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            ></feColorMatrix>
                            <feOffset dy="8"></feOffset>
                            <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
                            <feComposite in2="hardAlpha" operator="out"></feComposite>
                            <feColorMatrix values="0 0 0 0 0.988235 0 0 0 0 0.639216 0 0 0 0 0.529412 0 0 0 0.35 0"></feColorMatrix>
                            <feBlend
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_11028_14094"
                            ></feBlend>
                            <feBlend
                                in="SourceGraphic"
                                in2="effect1_dropShadow_11028_14094"
                                result="shape"
                            ></feBlend>
                        </filter>
                    </defs>
                </svg>
                <h3 style={{ fontFamily: 'Urbanist', marginBlock: 0, marginTop: '1rem', color: '#020202' }}>This link has expired.</h3>
                <p style={{ fontFamily: 'Urbanist', fontWeight: 200, color: '#2C2C2C' }}>Thank you!</p>
            </Card>
        </Container>
    )
}

export default LinkExpired;