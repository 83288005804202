import { makeStyles } from '@material-ui/core/styles';
import TextFieldMui from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { colors } from '../../Styles/colors';

interface StyleAttributes {
    backgroundColor: string;
}

const TextField = (props: any) => {
    let {
        id,
        label,
        value,
        type,
        variant,
        table,
        name,
        styles,
        onChange,
        error,
        required,
        helperText,
        InputProps,
        startAdornment,
        endAdornment,
        ...other
    } = props;

    if (table) {
        let { tableData } = table.columnDef;

        name = table.columnDef.field;
        onChange = (e: any) => table.onFilterChanged(tableData.id, e.target.value);
        styles = {
            ...styles,
            width: tableData.width || tableData.initialWidth,
            padding: 'unset !important',
            minWidth: 'auto',
        };
        value = tableData.filterValue;
    }

    let styleAttributes: StyleAttributes;

    if (variant === 'primary' || variant === 1) {
        styleAttributes = { backgroundColor: colors.white };
    } else {
        styleAttributes = { backgroundColor: colors.greyLight };
    }

    const useStyles = makeStyles({
        root: {
            borderRadius: '8px',
            backgroundColor: styleAttributes.backgroundColor,
            borderColor: colors.greyBorder,
            fontFamily: 'Urbanist'
        },
        rootFocused: {
            borderColor: `${colors.greyBorder} !important`,
            backgroundColor: 'transparent',
        },
        input: {
            color: colors.greyDark,
            ...(!other?.changeFont && { fontFamily: 'Urbanist' }),
        },
        labelRoot: (props: any) => (props?.customLabelRoot || {
            color: props?.labelRoot?.color || colors.greyDark,
            ...(!other?.changeFont && { fontFamily: 'Urbanist' }),
            '&:focus': {
                color: '#000000DE',
            },
        }),
        labelFocused: {
            color: colors.greyDark,
        },
        helperTextRoot: {
            fontFamily: 'Urbanist',
            margin: 0,
            marginTop: '0.5rem',
            textAlign: 'center',
            fontSize: '0.7775rem',
            fontWeight: 400,
        },
    });

    const classes = useStyles(other?.rootStyles);

    return (
        <>
            <TextFieldMui
                ref={other?.ref}
                autoComplete="Off"
                error={error}
                helperText={helperText}
                id={id}
                name={name}
                onChange={onChange}
                label={label || other.text}
                variant="outlined"
                size="small"
                fullWidth
                value={other?.disableValidation ? null : (value || value === 0 ? value : '')}
                {...other}
                InputProps={{
                    classes: {
                        root: classes.root,
                        focused: classes.rootFocused,
                        input: classes.labelRoot,
                    },
                    endAdornment: endAdornment,
                    startAdornment: startAdornment,
                    ...InputProps,
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                    },
                }}
                FormHelperTextProps={{ classes: { root: classes.helperTextRoot } }}
                type={type}
                required={required || false}
            />
        </>
    );
};

export default TextField;
