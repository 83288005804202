import React, { useState, useEffect } from 'react';

import {
    Container,
    Title,
    Subtite,
    OtpContainer,
} from './Mobile.styles';

import {
    Button,
    CheckInText
} from '../../Styles';
import { ErrorCode } from '../LoginForm/LoginForm.styles';

// Components
import OtpComponent from "../../Components/Otp";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { verifyOTP } from '../../Redux/User/User-actions';

import { useHistory } from 'react-router-dom';
import { RootState } from '../../Redux/root-reducer';

interface OtpProps {
    setStep: (step: number) => void;
    nextStep: number;
    resendOTP: () => void;
    sendData: boolean;
}

const Otp: React.FC<OtpProps> = ({ setStep, nextStep, resendOTP, sendData }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state: RootState) => state?.user);

    const [OTP, setOTP] = useState('');
    const [error, setError] = useState('');
    const [otpTimer, setOtpTimer] = useState(60);

    useEffect(() => {
        let otpInterval: any;
        if (otpTimer > 0) {
            otpInterval = setInterval(() => {
                setOtpTimer(otpTimer - 1);
            }, 1000);
        }
        return () => {
            clearInterval(otpInterval);
        };
    }, [otpTimer]);

    useEffect(() => {
        if (user.otpMessage) {
            setError(user.otpMessage);
        } else {
            setError('')
        }
    }, [user, history]);

    const proceed = () => {
        if (OTP.length < 6) {
            setError('Please enter a valid OTP');
            return;
        }

        let body: any = { "otp": OTP };
        if (user?.phone && user?.email) {
            body["email"] = user?.email;
            body["phone"] = user?.phone;
        } else if (user?.email) {
            body["email"] = user?.email;
        } else if (user?.phone) {
            body["phone"] = user?.phone;
        }

        dispatch(verifyOTP(user?.orgId || 1, body, () => { setStep(nextStep) }, sendData));
    };

    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: "column", width: "100vw", alignItems: "center" }}>
                <Title>OTP Verification</Title>
                <Subtite>OTP has been sent to {(user?.phone && user?.email) ? `${user?.phone} and ${user?.email}` : user?.phone || user?.email}</Subtite>
                <OtpContainer>
                    <OtpComponent
                        length={6}
                        isNumberInput
                        autoFocus
                        disabled={false}
                        onChangeOTP={(value) => { setOTP(value); setError(''); }}
                        inputStyle={{ width: '2rem', marginLeft: '0.5rem', marginRight: '0.5rem', height: '3rem', textAlign: 'center' }}
                    />
                </OtpContainer>
                <ErrorCode>{error}</ErrorCode>
                <Button onClick={proceed} style={{ backgroundColor: "#2484DC", marginTop: "0.5rem" }}>
                    <CheckInText>Proceed</CheckInText>
                </Button>
                <div onClick={() => { otpTimer == 0 && resendOTP() }} style={{ padding: '2rem' }}>
                    {otpTimer !== 0 ? (
                        <Subtite>
                            Resend code in 0{Math.floor(otpTimer / 60)}:{(otpTimer / 60 >= 1) ? '00' : otpTimer.toString().padStart(2, '0')}
                        </Subtite>
                    ) : (
                        <Subtite>Resend OTP</Subtite>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default Otp;
