import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

import { colors } from '../../Styles/colors';

let styleAttributes = { backgroundColor: colors.white, borderRadius: '8px', size: 'small' };

const useStyles = makeStyles({
    root: (props) => ({
        background: styleAttributes.backgroundColor,
        color: colors.menu,
        textTransform: 'capitalize',
        borderRadius: styleAttributes.borderRadius,
        fontFamily: 'Urbanist',
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': props?.root?.notch || {},
        //border: '2px solid #e0e0e0',
        '&.Mui-focused .MuiAutocomplete-inputRoot': {

            backgroundColor: 'transparent !important', // Change background color on focus
        },
    }),
    inputRoot: (props: any) => (props?.inputRoot || {
        borderRadius: styleAttributes.borderRadius,
        background: colors.greyLight,
        fontFamily: 'Urbanist',
        color: colors.menu,
    }),
    input: (props) => (props?.input || {
        fontFamily: 'Urbanist',
        color: colors.menu,
        textTransform: 'capitalize',
        border: '0px!important',
        padding: '0.3rem!important',
    }),

    formControl: {
        color: colors.menu,
        fontFamily: 'Urbanist',
        borderRadius: styleAttributes.borderRadius,
    },

    select: {
        borderRadius: styleAttributes.borderRadius,
    },
    nativeInput: {
        fontFamily: 'Urbanist',
        color: colors.menu,
        paddingLeft: '0rem',
        borderRadius: styleAttributes.borderRadius,
    },
    optionRoot: {
        textTransform: 'capitalize',
        fontFamily: 'Urbanist',
        color: colors.menu,
        margin: '0px 8px',
        paddingLeft: '0.5rem',
        borderRadius: '8px',
        width: 'auto',

        '&:hover': {
            backgroundColor: colors.lightenPrimary,
            color: colors.primary,
        },
    },
    textFieldRoot: {
        color: '#81899c',
        fontFamily: 'Urbanist'
    }
});

export default function AutoSelectDD({
    text,
    label,
    dropDownOptions,
    options = [],
    name,
    value,
    onChange,
    forInput,
    defaultValue,
    disableClearable,
    ...other
}: any) {
    const classes = useStyles(other?.customStyle);

    const hChange = (e: any, inp: any) => {
        let value;
        if (inp) {
            value = inp?.value || inp.map((i: any) => i.value);
        } else {
            if (other.multiple) {
                value = [];
            } else {
                value = '';
            }
        }
        onChange({ target: { name, value } });
    };

    const multipleOptions: any = {};

    options = (dropDownOptions || options)?.map((o: any) => {
        const value = o.id || o.name || o;
        const label = typeof o === 'string' ? o : o.label || o.name || ' '; // ' ' -> Label not given // breaking on falsy value ''

        const option = {
            value,
            label,
        };
        multipleOptions[value] = option;
        return option;
    });
    value = other.multiple ? value?.map((oId: any) => multipleOptions[oId]) || [] : value || null;
    options?.forEach((option: any) => {
        if (option.value === value) value = option.label;
    });

    return (
        <Autocomplete
            disableClearable={disableClearable ? disableClearable : false}
            style={{ opacity: other.disabled ? '0.5' : '1' }}
            defaultValue={defaultValue}
            size="small"
            name={name}
            disabled={options?.length === 0 || !!other.disabled}
            classes={{
                root: classes.root,
                input: classes.input,
                inputRoot: classes.inputRoot,
                option: classes.optionRoot,
                //formControl: classes.formControl,
            }}
            filterSelectedOptions={!!other.multiple}
            onChange={hChange}
            value={value}
            id="combo-box-demo"
            options={options}
            getOptionLabel={(option: any) => {
                const label = option?.label || option || '';
                if (typeof label === 'string') {
                    return label;
                } else {
                    return (options?.length ? options?.find((o: any) => o.value === label)?.label : '') || option; // label in find comin as id here
                }
            }}
            getOptionSelected={(option: any, value) => {
                return option?.value === (value?.value || value);
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        autoComplete="off"
                        {...params}
                        variant="outlined"
                        size="small"
                        label={text || label}
                        name={name}
                        InputLabelProps={{
                            classes: {
                                root: classes.textFieldRoot,
                            },
                        }}
                        {...forInput}
                    />
                );
            }}
            {...other}
        />
    );
}
