import { Api1 } from '../../axios/axiosConfig';
import { DoorTypes } from './DoorUnlock-types';
import { Dispatch } from 'redux';

export interface DoorActions {
  type: DoorTypes,
  payload?: string | boolean | any
}

export const DoorUnlockStart = () => ({
  type: DoorTypes.DOOR_UNLOCK_START,
  payload: null
})

export const DoorUnlockSuccess = (data: {
  message: string,
  title: string
}) => ({
  type: DoorTypes.DOOR_UNLOCK_SUCCESS,
  payload: data
})

export const DoorUnlockFail = (data: {
  message: string,
  title: string
}) => ({
  type: DoorTypes.DOOR_UNLOCK_FAIL,
  payload: data
})


export const DoorUnlock = (
  orgId: number,
  doorId: number,
  meetingId: number,
) => {
  return (dispatch: Dispatch) => {
    dispatch(DoorUnlockStart())
    let header = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("sessionToken")
      }
    }
    Api1.post(`/v1/organisations/${orgId}/meeting/${meetingId}/accessPoint/${doorId}/remoteUnlock/webApp`, {}, header).then((res) => {
      let data = {
        message: 'Access Denied',
        title: 'DOOR UNLOCKED'
      }
      if (res?.data?.type === 'success') {
        data = {
          message: res?.data?.message,
          title: "DOOR UNLOCKED"
        }
      }
      if (res?.data?.type === 'error') {
        data = {
          message: res?.data?.message?.displayMessage,
          title: "DOOR LOCKED"
        }
      }

      dispatch(DoorUnlockSuccess(data))
    }).catch(err => {
      let message = err?.response?.data?.message?.displayMessage || 'Error'
      if (message == "Error: Not able to parse token") {
        message = "Please Check-in/View Details from the link received if meeting is scheduled and try again."
      }
      const data = {
        message: message,
        title: 'DOOR LOCKED'
      }
      dispatch(DoorUnlockFail(data))
    })

  }
}


