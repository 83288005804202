import React, { useState, useEffect } from 'react';

import {
    Container,
    Content,
    RelativeContainer,
    WordCount,
} from './Comment.styles';
import { ErrorCode } from '../LoginForm/LoginForm.styles';


import { useDispatch, useSelector } from 'react-redux';
import { updateComment } from '../../Redux/User/User-actions';

import {
    Button,
    CheckInText,
} from '../../Styles';
import { RootState } from '../../Redux/root-reducer';
import BorderedTextArea from '../../Components/BorderedTextArea/BorderedTextArea';

interface CommentProps {
    setStep: (step: number) => void;
    nextStep: number;
}

const Comment: React.FC<CommentProps> = ({ setStep,nextStep }) => {

    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state?.user);

    const [error, setError] = useState('');
    const [visitorComments, setVisitorComments] = useState('');

    useEffect(() => {
        const {
            visitorComments,
        } = user;

        if (visitorComments !== '') {
            setVisitorComments(visitorComments);
        }
    }, [user]);

    const proceed = () => {
        if (visitorComments.length > 120) {
            setError('Visitor comment info should be less than 120 characters long.');
            setTimeout(() => setError(''), 2000);
            return;
        }

        dispatch(updateComment(visitorComments));
        setStep(nextStep)
    };

    return (
        <Container>
            <Content>
                <RelativeContainer>
                    <BorderedTextArea
                        label="Visitor Comment"
                        onSetVal={(value) => value.length <= 120 && setVisitorComments(value)}
                        value={visitorComments}
                        disabled={false}
                        minHeight="180px"
                    />
                    <WordCount>{120 - visitorComments.length}</WordCount>
                </RelativeContainer>
                <ErrorCode>{error}</ErrorCode>
                <Button onClick={proceed} style={{ backgroundColor: '#2484DC', marginTop: '2.5rem' }}>
                    <CheckInText>Proceed</CheckInText>
                </Button>
            </Content>
        </Container>
    );
};

export default Comment;
