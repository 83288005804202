import React, { useState, useEffect } from 'react';

import Loader from '../../Components/Loader/Loader';
import { Api1 } from '../../axios/axiosConfig';
import QRScan from '../../assets/icon/QRScan';
import { Container, Content, Divider, ErrorContainer, Highlight, InfoText, List, TermsText, Title, Title2 } from './Checkin.styles';
import { useLocation } from 'react-router-dom';
import Logo from '../../Components/Logo/Logo';
import { ErrorCode } from '../../Styles';
import { QRCodeCanvas } from 'qrcode.react';
const qs = require('qs');


const Checkin = () => {

    const location = useLocation();

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const orgId = queryParams.orgId;
    const meetingId = queryParams.meetingId;

    const [qrDetails, setQrDetails] = useState({
        accessMode: 0,
        qrCode: '',
        name: '',
        email: '',
        phone: '',
        orgName: '',
        scheduledDate: '',
        startTime: '',
        endTime: '',
        permissions: [],
        orgLogo: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');



    useEffect(() => {
        setLoading(true);
        Api1.get(`/v1/organisations/${orgId}/meeting/${meetingId}/qrDetails`, { headers: { 'Authorization': localStorage.getItem("sessionToken") || '' } })
            .then(({ data }: any) => {
                setError('')
                setQrDetails(data?.message)
            }).catch((err) => {
                setError(err?.response?.data?.message?.displayMessage || err?.response?.data?.message?.errorMessage || "Something went wrong!")
            }).finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Container>
            {
                loading ? <Loader /> :
                    error ?
                        <ErrorContainer>
                            <ErrorCode>{error}</ErrorCode>
                        </ErrorContainer>
                        :
                        <Content>
                            <Logo source={`data:image/png;base64,${qrDetails?.orgLogo}`} />
                            {
                                qrDetails?.accessMode === 1 ? <QRCodeCanvas marginSize={2} style={{ height: 'auto', width: '80%' }} value={qrDetails.qrCode} /> :
                                    qrDetails?.accessMode === 2 ? <QRScan style={{ width: '52%' }} /> :
                                        qrDetails?.accessMode === 0 ? <Content style={{ alignItems: 'flex-start', marginTop: '30%' }}>
                                            <Title style={{ color: '#2484DC', fontSize: '1.5rem', textAlign: 'left' }}>Hi, {qrDetails?.name}</Title>
                                            <div style={{ width: "100%" }}>
                                                <TermsText>
                                                    Thank you for checking in. Please expect your visit approval over {(qrDetails?.phone && qrDetails?.email) ? 'SMS or Email' : qrDetails?.phone ? 'SMS' : 'Email'}
                                                </TermsText>
                                            </div>
                                        </Content> : <></>
                            }
                            {
                                qrDetails?.accessMode === 3 ?
                                    <>
                                        <Title2>Hi, {qrDetails?.name}</Title2>
                                        <TermsText>
                                            Thank you for checking in. Frontdesk will assign you a card to access doors.
                                        </TermsText>
                                    </> :
                                    qrDetails?.accessMode == 0 ? <></> :
                                        <>
                                            <Title>{qrDetails?.accessMode === 2 ? "QR Scan" : "QR code for door access"}</Title>
                                            <Divider />
                                            <div style={{ width: '100%', paddingLeft: '5%' }}>
                                                <InfoText style={{ marginTop: '0' }}>Name: <Highlight>{qrDetails.name}</Highlight></ InfoText>
                                                {qrDetails.email && <InfoText>Email: <Highlight>{qrDetails.email}</Highlight></InfoText>}
                                                {qrDetails.phone && <InfoText>Phone: <Highlight>{qrDetails.phone}</Highlight></InfoText>}
                                            </div>
                                            <Divider />
                                            <div style={{ width: '95%', paddingLeft: '5%' }}>
                                                <InfoText>Hi, <Highlight>{qrDetails.name}</Highlight></InfoText>
                                                <InfoText>{qrDetails?.orgName} has granted you access to the following doors on {qrDetails?.scheduledDate}, from {qrDetails?.startTime} to {qrDetails?.endTime} {qrDetails?.tzAbbr}</InfoText>
                                                {
                                                    qrDetails?.permissions?.length > 0 ?
                                                        <ul>
                                                            {qrDetails?.permissions?.map(ap => <List>{ap}</List>)}
                                                        </ul> :
                                                        <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: '5%', gap: '0.5rem' }}>
                                                            <Highlight style={{ fontWeight: 400, color: '#E59D20' }}>No door permissions assigned.</Highlight>
                                                            <Highlight style={{ fontWeight: 400, color: '#E59D20' }}>Please contact your host.</Highlight>
                                                        </div>
                                                }
                                                <InfoText>{qrDetails?.accessMode === 2 ? "Scan the QR code next to the door to unlock it." :
                                                    "Use the above qr code to access the doors. Point the QR code displayed above at the QR code scanner."
                                                }</InfoText>
                                            </div>
                                        </>
                            }
                        </Content>
            }
        </Container>
    );
};

export default Checkin;
