import React, { Component } from 'react'

import {
    Container,
    Footer,
    Image,
    FooterText
} from './Splash.styles';


// importing svg images
import defaultLogo from '../../assets/images/spintly.png'
import { Title } from '../Mobile/Mobile.styles';
import config from '../../Configurations';
let theme = config?.theme?.name;
let logo: any = null;
import(`../../assets/images/${theme}.png`)
    .then((module) => {
        logo = module.default;
    })
    .catch(() => {
        logo = defaultLogo;
    });




export class Splash extends Component {
    render() {
        return (
            <Container>
                <div style={{ alignItems: "center", display: 'flex' }}>
                    <Title>Visitor Management App</Title>
                </div>
                <Footer>
                    <FooterText>Powered by   :</FooterText>
                    <Image src={logo} />
                </Footer>
            </Container>
        )
    }
}


export default Splash
