import React from 'react';

import {
    Container,
    CountryCode,
    InputContainer,
} from './LoginForm.styles';

import {
    Button,
    CheckInText
} from '../../Styles';


// redux
import { useSelector } from 'react-redux';

import { RootState } from '../../Redux/root-reducer';

interface LoginFormProps {
    setStep: (step: number) => void;
    nextStep: number;
}

const LoginFormView: React.FC<LoginFormProps> = ({ setStep, nextStep }) => {

    const user = useSelector((state: RootState) => state?.user);

    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: "column", width: "95%", alignItems: "center", marginBottom: '2rem' }}>
                {user.visitorNameEnabled && (
                    <InputContainer>
                        <CountryCode style={{ textAlign: "left" }} Valid={true}>NAME</CountryCode>
                        <div className="tel-input">
                            <input className="form-control"
                                style={{ fontFamily: "Lato-Regular", width: "240px" }}
                                disabled={true}
                                value={user?.visitorName}
                            />
                        </div>
                    </InputContainer>
                )}
                {user.visitorEmailEnabled && (
                    <InputContainer>
                        <CountryCode style={{ textAlign: "left" }} Valid={true}>EMAIL</CountryCode>
                        <div className="tel-input">
                            <input className="form-control"
                                style={{ fontFamily: "Lato-Regular", width: "240px" }}
                                disabled={true}
                                value={user?.email}
                            />
                        </div>
                    </InputContainer>
                )}
                {user.visitorPhoneEnabled && (
                    <InputContainer>
                        <CountryCode style={{ textAlign: "left" }} Valid={true}>PHONE</CountryCode>
                        <div className="tel-input">
                            <input className="form-control"
                                style={{ fontFamily: "Lato-Regular", width: "240px" }}
                                disabled={true}
                                value={user?.phone}
                            />
                        </div>
                    </InputContainer>
                )}
                <InputContainer>
                    <CountryCode style={{ textAlign: "left" }} Valid={true}>SITE</CountryCode>
                    <div className="tel-input">
                        <input className="form-control"
                            style={{ fontFamily: "Lato-Regular", width: "240px" }}
                            disabled={true}
                            value={user?.siteName}
                        />
                    </div>
                </InputContainer>
                {user.personToMeetEnabled && (
                    <InputContainer>
                        <CountryCode style={{ textAlign: "left" }} Valid={true}>PERSON TO MEET</CountryCode>
                        <div className="tel-input">
                            <input className="form-control"
                                style={{ fontFamily: "Lato-Regular", width: "240px" }}
                                disabled={true}
                                value={user?.toMeet?.name}
                            />
                        </div>
                    </InputContainer>
                )}
                {user.purposeOfMeetingEnabled && (
                    <InputContainer>
                        <CountryCode style={{ textAlign: "left" }} Valid={true}>MEETING PURPOSE</CountryCode>
                        <div className="tel-input">
                            <input className="form-control"
                                style={{ fontFamily: "Lato-Regular", width: "240px" }}
                                disabled={true}
                                value={user?.meetingPurpose}
                            />
                        </div>
                    </InputContainer>
                )}
                {user.startTimeEnabled && (
                    <InputContainer>
                        <CountryCode style={{ textAlign: "left" }} Valid={true}>START TIME</CountryCode>
                        <div className="tel-input">
                            <input className="form-control"
                                style={{ fontFamily: "Lato-Regular", width: "240px" }}
                                disabled={true}
                                value={user?.startTime}
                            />
                        </div>
                    </InputContainer>
                )}
                {user.endTimeEnabled && (
                    <InputContainer>
                        <CountryCode style={{ textAlign: "left" }} Valid={true}>END TIME</CountryCode>
                        <div className="tel-input">
                            <input className="form-control"
                                style={{ fontFamily: "Lato-Regular", width: "240px" }}
                                disabled={true}
                                value={user?.endTime}
                            />
                        </div>
                    </InputContainer>
                )}
                {user?.hostAdditionalInfo && <InputContainer>
                    <CountryCode style={{ textAlign: "left" }} Valid={true}>HOST COMMENT</CountryCode>
                    <div className="tel-input">
                        <input className="form-control"
                            style={{ fontFamily: "Lato-Regular", width: "240px" }}
                            disabled={true}
                            value={user?.hostComments}
                        />
                    </div>
                </InputContainer>}
                <Button onClick={() => { setStep(nextStep) }} style={{ backgroundColor: "#2484DC", marginTop: "0.5rem" }}>
                    <CheckInText>
                        PROCEED
                    </CheckInText>
                </Button>
            </div>
        </Container>
    );
};

export default LoginFormView;
