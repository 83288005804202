import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// importing fonts
import './fonts/Lato-Light.ttf'
import './fonts/Lato-Regular.ttf'
import './fonts/Lato-Bold.ttf'
import './fonts/Poppins-Medium.ttf';
import './fonts/Poppins-Regular.ttf';
import './fonts/Poppins-SemiBold.ttf';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { colors } from './Styles/colors';

const theme = createTheme({
  palette: {
      primary: { main: colors.primary },

      secondary: {
          main: colors.accent,
          contrastText: colors.white,
      },
  },
});


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
