import spintlyConfig from './spintlyConfig';
let NODE_ENV = process.env.REACT_APP_NODE_ENV;

let config = {
    copyrightName: 'Spintly',
    salesEmail: 'sales@spintly.com',
    domain: 'spintly',
    theme: {
        name: 'spintly',
    },
    baseURL: ''
};

switch (NODE_ENV) {
    default:
        config = spintlyConfig;
        break;
}

export default config;
