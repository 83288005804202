import { UserTypes } from './User-types';
import { Dispatch } from 'redux';
import { Api1 } from '../../axios/axiosConfig'
import { base64ToFile } from '../../utils';
import { saveVisitorInfoSuccess } from '../Visitor/Visitor-actions';

export interface UserActions {
    type: UserTypes,
    payload?: string | boolean | any
}


export const LinkStart = () => ({
    type: UserTypes.LINK_START,
    payload: 'string'
})

export const LinkStartSuccess = (object: {
    orgId: number
    orgLogo: string
    showVisitorPhoto: boolean
    organisationName: string,
    terms: string,
    imageData: {
        format: string,
        height: number,
        quality: number,
        width: number
    }
    visitorNameEnabled: boolean,
    visitorPhoneEnabled: boolean,
    visitorEmailEnabled: boolean,
    purposeOfMeetingEnabled: boolean,
    personToMeetEnabled: boolean,
    tAndCFormEnabled: boolean,
    organisationId: number,
    kioskId: number,
    additionalInfoEnabled: boolean,
    hostAdditionalInfo: boolean,
    visitorAdditionalInfo: boolean,
    startTimeEnabled: boolean,
    endTimeEnabled: boolean,
    meetingPurposes: Object,
    personsToMeet: Object,
    otpVerificationEnabled: boolean,
    siteId: number,
}) => ({
    type: UserTypes.LINK_SUCCESS,
    payload: object
})

export const LinkUpdateTerms = (terms: string) => ({
    type: UserTypes.LINK_SUCCESS_UPDATE_TERMS,
    payload: terms
})

export const LinkStartFail = (error: string) => ({
    type: UserTypes.LINK_FAIL,
    payload: error
})

export const getOrganisationData = (kioskId: string, authCode: string) => {
    return (dispatch: Dispatch) => {
        dispatch(LinkStart());
        let header = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        Api1.get(`/v1/organisations/orgData?kioskId=${kioskId}&authCode=${authCode}`, header).then((res: any) => {

            const data = res.data.message.organisationData
            const orgId = data.organisationId
            const orgLogo = data.orgLogo
            const showVisitorPhoto = data.visitorPhotoEnabled
            const organisationName = data.organisationName
            const terms = ''
            if (data.tAndCFile) {
                LinkToBase64(dispatch, data.tAndCFile, LinkUpdateTerms);
            }

            const visitorNameEnabled = true
            const visitorPhoneEnabled = data.visitorPhoneEnabled
            const visitorEmailEnabled = data.visitorEmailEnabled
            const purposeOfMeetingEnabled = data?.meetingPurposes?.length === 0 ? false : true;
            const personToMeetEnabled = data.personToMeetEnabled
            const tAndCFormEnabled = data.tAndCFormEnabled
            const organisationId = data.organisationId
            const kioskId = data.kioskDetails.id
            const additionalInfoEnabled = data.additionalInfoEnabled
            const hostAdditionalInfo = data.hostAdditionalInfo
            const visitorAdditionalInfo = data.visitorAdditionalInfo
            const startTimeEnabled = data.startTimeEnabled
            const endTimeEnabled = data.endTimeEnabled
            const otpVerificationEnabled = data.otpVerificationEnabled
            let meetingPurposes = []
            let personsToMeet = []

            if (res?.data?.message?.meetingPurposes) {
                meetingPurposes = res?.data?.message?.meetingPurposes
            }

            if (res?.data?.message?.users) {
                personsToMeet = res?.data?.message?.users
            }

            let imageData = {
                format: "JPEG",
                height: 650,
                quality: 70,
                width: 650
            }
            if (res.data.message.imageData !== undefined) {
                imageData = {
                    format: res.data.message.imageData.format,
                    height: res.data.message.imageData.height,
                    quality: res.data.message.imageData.quality,
                    width: res.data.message.imageData.width
                }
            }
            let object = {
                orgId,
                orgLogo,
                showVisitorPhoto,
                organisationName,
                terms,
                imageData,
                visitorNameEnabled,
                visitorPhoneEnabled,
                visitorEmailEnabled,
                purposeOfMeetingEnabled,
                personToMeetEnabled,
                tAndCFormEnabled,
                organisationId,
                kioskId,
                additionalInfoEnabled,
                hostAdditionalInfo,
                visitorAdditionalInfo,
                startTimeEnabled,
                endTimeEnabled,
                meetingPurposes,
                personsToMeet,
                otpVerificationEnabled,
                siteId: data?.kioskDetails?.siteId
            }

            dispatch(LinkStartSuccess(object))
        }).catch(err => {
            if (err.response) {
                dispatch(LinkStartFail(err?.response?.data?.message?.displayMessage || err?.response?.data?.message?.errorMessage || err.response.data.message))
            } else {
                dispatch(LinkStartFail("Invalid route"))
            }

        })
    }
}

export const EmailLink = () => ({
    type: UserTypes.EMAIL_LINK_START,
    payload: 'string'
})

export const EmailLinkSuccess = (object: {
    orgId: number
    orgLogo: string
    showVisitorPhoto: boolean
    organisationName: string,
    terms: string,
    imageData: {
        format: string,
        height: number,
        quality: number,
        width: number
    },
    visitorNameEnabled: boolean,
    visitorPhoneEnabled: boolean,
    visitorEmailEnabled: boolean,
    purposeOfMeetingEnabled: boolean,
    personToMeetEnabled: boolean,
    tAndCFormEnabled: boolean,
    organisationId: number,
    additionalInfoEnabled: boolean,
    hostAdditionalInfo: boolean,
    visitorAdditionalInfo: boolean,
    startTimeEnabled: boolean,
    endTimeEnabled: boolean,
    otpVerificationEnabled: boolean,
    siteId: number,
    siteName: string,
}) => ({
    type: UserTypes.EMAIL_LINK_SUCCESS,
    payload: object
})

export const EmailLinkFail = (error: string) => ({
    type: UserTypes.EMAIL_LINK_FAIL,
    payload: error
})

export const UpdateEmailLinkSuccess = (payload: any) => ({
    type: UserTypes.UPDATE_EMAIL_LINK_SUCCESS,
    payload: payload
})

export const getEmailLinkData = (mId: number, accessKey: string) => {
    return (dispatch: Dispatch) => {
        dispatch(EmailLink());
        Api1.get(`/v1/organisations/meeting/${mId}/meetingData?accessKey=${accessKey}`,).then((res: any) => {

            const data = res.data.message
            const orgData = res.data.message.organisationData

            const orgId = orgData.organisationId
            let orgLogo = ''
            if (orgData.orgLogo !== undefined) {
                orgLogo = orgData.orgLogo
            }
            const showVisitorPhoto = orgData?.visitorPhotoEnabled
            const organisationName = orgData.organisationName
            const terms = orgData.tAndCFile

            let imageData = {
                format: "JPEG",
                height: 650,
                quality: 70,
                width: 650
            }
            const visitorNameEnabled = orgData.visitorNameEnabled
            const visitorPhoneEnabled = orgData.visitorPhoneEnabled
            const visitorEmailEnabled = orgData.visitorEmailEnabled
            const purposeOfMeetingEnabled = orgData.purposeOfMeetingEnabled
            const personToMeetEnabled = orgData.personToMeetEnabled
            const tAndCFormEnabled = orgData.tAndCFormEnabled
            const additionalInfoEnabled = orgData.additionalInfoEnabled
            const hostAdditionalInfo = orgData.hostAdditionalInfo
            const visitorAdditionalInfo = orgData.visitorAdditionalInfo
            const otpVerificationEnabled = orgData.otpVerificationEnabled


            if (res.data.message.imageData !== undefined) {
                imageData = {
                    format: res.data.message.imageData.format,
                    height: res.data.message.imageData.height,
                    quality: res.data.message.imageData.quality,
                    width: res.data.message.imageData.width
                }
            }

            let visitorInfo = data.visitorInfo
            let email = ''
            let organisationId = undefined
            let visitorId = undefined
            let visitorName = ''
            let visitorPhoto = ''
            let phoneNumber = ''
            if (visitorInfo !== undefined) {
                if (visitorInfo.email !== null && visitorInfo.email !== undefined) {
                    email = visitorInfo.email
                }

                if (visitorInfo.phone !== null && visitorInfo.phone !== undefined) {
                    phoneNumber = visitorInfo.phone
                }

                if (visitorInfo.organisationId !== null && visitorInfo.organisationId !== undefined) {
                    organisationId = visitorInfo.organisationId
                }


                if (visitorInfo.visitorId !== null && visitorInfo.visitorId !== undefined) {
                    visitorId = visitorInfo.visitorId
                }


                if (visitorInfo.visitorName !== null && visitorInfo.visitorName !== undefined) {
                    visitorName = visitorInfo.visitorName
                }

                if (visitorInfo.visitorPhoto !== null && visitorInfo.visitorPhoto !== undefined) {
                    visitorPhoto = visitorInfo.visitorPhoto
                }
            }



            let meetingInfo = data.meetingInfo

            let meetingId = 0
            let meetingPurpose = ''
            let toMeet = {
                id: undefined,
                name: ''
            }

            let hostComments = "-";
            let startTime = ''
            let endTime = '';
            let startDate = '';
            let siteId = undefined;
            let siteName = '';

            if (meetingInfo !== undefined) {
                meetingId = meetingInfo.meetingId
                if (meetingInfo.meetingPurpose !== undefined) {
                    meetingPurpose = meetingInfo.meetingPurpose
                }
                if (meetingInfo.toMeet !== undefined) {
                    toMeet = meetingInfo.toMeet
                }
                if (meetingInfo.hostComments !== undefined) {
                    hostComments = meetingInfo.hostComments
                }
                if (meetingInfo.startTime !== null && meetingInfo.startTime !== undefined) {
                    startTime = meetingInfo.startTime
                }

                if (meetingInfo.startDate !== null && meetingInfo.startDate !== undefined) {
                    startDate = meetingInfo.startDate
                }

                if (meetingInfo.endTime !== null && meetingInfo.endTime !== undefined) {
                    endTime = meetingInfo.endTime
                }

                if (meetingInfo.siteId !== null && meetingInfo.siteId !== undefined) {
                    siteId = meetingInfo.siteId
                }

                if (meetingInfo.siteName !== null && meetingInfo.siteName !== undefined) {
                    siteName = meetingInfo.siteName
                }
            }


            let personsToMeet = []
            if (data.users !== undefined) {
                personsToMeet = data.users
            }


            let sessionToken = ''
            if (data.sessionToken !== undefined && data.sessionToken !== null) {
                sessionToken = data.sessionToken
                localStorage.setItem("sessionToken", sessionToken)
            }

            let visitorTypes = {
                unscheduledNew: false,
                unscheduledExisting: false,
                scheduled: false,
                checkedIn: false,
                waitingForApproval: true
            }
            if (data.visitorTypes !== undefined) {
                visitorTypes = data.visitorTypes
            }


            let object = {
                visitorTypes: visitorTypes,
                meetingPurposes: data.meetingPurposes,
                email: email,
                orgId: organisationId,
                visitorId: visitorId,
                visitorName: visitorName,
                visitorPhoto: visitorPhoto,
                meetingId: meetingId,
                meetingPurpose: meetingPurpose,
                toMeet: toMeet,
                hostComments: hostComments,
                personsToMeet: personsToMeet,
                sessionToken: sessionToken,
                startTime,
                endTime,
                startDate,
            }
            let EmaillinkData = {
                orgId,
                orgLogo,
                showVisitorPhoto,
                organisationName,
                terms,
                imageData,
                visitorNameEnabled,
                visitorPhoneEnabled,
                visitorEmailEnabled,
                purposeOfMeetingEnabled,
                startTimeEnabled: orgData?.startTimeEnabled,
                endTimeEnabled: orgData?.endTimeEnabled,
                personToMeetEnabled,
                tAndCFormEnabled,
                organisationId,
                additionalInfoEnabled,
                hostAdditionalInfo,
                visitorAdditionalInfo,
                otpVerificationEnabled,
                siteId,
                siteName
            }

            if (res.data.type === 'success') {
                dispatch(MobileNumberSuccess(phoneNumber))
                dispatch(OTPSuccess(object))
                dispatch(EmailLinkSuccess(EmaillinkData))
                if (EmaillinkData?.terms) {
                    LinkToBase64(dispatch, EmaillinkData?.terms, UpdateEmailLinkSuccess)
                }
            }
        }).catch(err => {
            if (err.response) {
                let errorMsg = err?.response?.data?.message?.displayMessage || err?.response?.data?.message?.errorMessage || err?.response?.data?.message
                dispatch(EmailLinkFail(errorMsg))
                dispatch(MobileNumberFail(errorMsg))
                dispatch(OTPFail(errorMsg))
                dispatch(EmailLinkFail(errorMsg))
            } else {
                dispatch(EmailLinkFail("Invalid route"))
                dispatch(MobileNumberFail("Invalid route"))
                dispatch(OTPFail("Invalid route"))
                dispatch(EmailLinkFail("Invalid route"))
            }
        })
    }
}

export const MobileNumberStart = () => ({
    type: UserTypes.REGISTERUSER_START,
    payload: 'string'
})

export const MobileNumberSuccess = (phoneNumber: string) => ({
    type: UserTypes.REGISTERUSER_PHONE_SUCCESS,
    payload: phoneNumber
})

export const EmailSuccess = (email: string) => ({
    type: UserTypes.REGISTERUSER_EMAIL_SUCCESS,
    payload: email
})

export const MobileNumberFail = (error: string) => ({
    type: UserTypes.REGISTERUSER_FAIL,
    payload: error
})

export const setLoader = (loaded: boolean) => ({
    type: UserTypes.SET_LOADER,
    payload: loaded
})

export const generateOtp = (orgID: number, body: any) => {
    return (dispatch: Dispatch) => {
        dispatch(MobileNumberStart());
        let header = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        Api1.post(`/v1/organisations/${orgID}/visitors/generateOtp/webApp`, body, header).then((res) => {
            dispatch(setLoader(false))
        }).catch(err => {
            dispatch(MobileNumberFail("Error sending otp request"))
        })
    }
}

export const LoginType = (type: string) => ({
    type: UserTypes.LOGIN_TYPE,
    payload: type
})

export const OTPStart = () => ({
    type: UserTypes.OTP_START,
    payload: 'string'
})

export const OTPSuccess = (object: {
    visitorTypes: object,
    meetingPurposes: object,
    email: string,
    orgId: number,
    visitorId: number,
    visitorName: string,
    visitorPhoto: string,
    meetingId: number,
    meetingPurpose: string,
    toMeet: object,
    hostComments: string,
    personsToMeet: object,
    sessionToken: string,
    startTime: string | undefined,
    endTime: string | undefined,
    startDate: string | undefined,
}) => ({
    type: UserTypes.OTP_SUCCESS,
    payload: object
})

export const OTPFail = (error: string) => ({
    type: UserTypes.OTP_FAIL,
    payload: error
})

export const verifyOTP = (orgId: number, body: object, success: () => void, sendData: boolean) => {
    let reqBody = {
        ...body,
        sendData: sendData
    }
    return (dispatch: Dispatch) => {
        dispatch(OTPStart());
        let header = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        Api1.post(`/v1/organisations/${orgId}/visitors/verifyOtp/webApp`, reqBody, header).then((res: any) => {
            const data = res.data.message
            let visitorInfo = data.visitorInfo
            let email = ''
            let organisationId = orgId
            let visitorId = undefined
            let visitorName = ''
            let visitorPhoto = ''
            if (data?.meetingInfo?.meetingId == 0 && data?.message?.length > 0) {
                dispatch(OTPFail(data?.message))
                return;
            }
            if (visitorInfo !== undefined) {
                if (visitorInfo.email !== null && visitorInfo.email !== undefined) {
                    email = visitorInfo.email
                }

                if (visitorInfo.organisationId !== null && visitorInfo.organisationId !== undefined) {
                    organisationId = visitorInfo.organisationId
                }


                if (visitorInfo.visitorId !== null && visitorInfo.visitorId !== undefined) {
                    visitorId = visitorInfo.visitorId
                }


                if (visitorInfo.visitorName !== null && visitorInfo.visitorName !== undefined) {
                    visitorName = visitorInfo.visitorName
                }

                if (visitorInfo.visitorPhoto !== null && visitorInfo.visitorPhoto !== undefined) {
                    visitorPhoto = visitorInfo.visitorPhoto
                }
            }



            let meetingInfo = data.meetingInfo
            let meetingId = 0
            let meetingPurpose = ''
            let toMeet = {
                id: undefined,
                name: ''
            }

            let hostComments = "-";
            let startTime = ''
            let endTime = '';
            let startDate = '';

            if (meetingInfo !== undefined) {
                meetingId = meetingInfo.meetingId
                if (meetingInfo.meetingPurpose !== null && meetingInfo.meetingPurpose !== undefined) {
                    meetingPurpose = meetingInfo.meetingPurpose
                }
                if (meetingInfo.toMeet !== null && meetingInfo.toMeet !== undefined) {
                    toMeet = meetingInfo.toMeet
                }

                if (meetingInfo.hostAdditionalInfo !== undefined) {
                    hostComments = meetingInfo.hostAdditionalInfo
                }
                if (meetingInfo.startTime !== null && meetingInfo.startTime !== undefined) {
                    startTime = meetingInfo.startTime
                }

                if (meetingInfo.startDate !== null && meetingInfo.startDate !== undefined) {
                    startDate = meetingInfo.startDate
                }

                if (meetingInfo.endTime !== null && meetingInfo.endTime !== undefined) {
                    endTime = meetingInfo.endTime
                }
            }



            let personsToMeet = []
            if (data.users !== undefined) {
                personsToMeet = data.users
            }


            let sessionToken = ''
            if (data.sessionToken !== undefined && data.sessionToken !== null) {
                sessionToken = data.sessionToken
                localStorage.setItem("sessionToken", sessionToken);
            }

            let visitorTypes = {
                unscheduledNew: false,
                unscheduledExisting: false,
                scheduled: false,
                checkedIn: false,
                waitingForApproval: true
            }
            if (data.visitorTypes !== undefined) {
                visitorTypes = data.visitorTypes
            }

            let object = {
                visitorTypes: visitorTypes,
                meetingPurposes: data.meetingPurposes,
                email: email,
                orgId: organisationId,
                visitorId: visitorId,
                visitorName: visitorName,
                visitorPhoto: visitorPhoto,
                meetingId: meetingId,
                meetingPurpose: meetingPurpose,
                toMeet: toMeet,
                personsToMeet: personsToMeet,
                sessionToken: sessionToken,
                hostComments: hostComments,
                startTime,
                endTime,
                startDate,
            }
            if (res.data.type === 'success') {
                if (sendData) {
                    dispatch(OTPSuccess(object))
                } else {
                    dispatch(setLoader(false))
                }
            } else {
                dispatch(OTPFail("Error verifying otp"))
            }

            success && success()
        }).catch(err => {
            if (err.response) {
                dispatch(OTPFail(err?.response?.data?.message?.displayMessage || err?.response?.data?.message?.errorMessage || err?.response?.data?.message))
            } else {
                dispatch(OTPFail("Error verifying otp"))
            }
        })
    }
}

export const saveVisitorData = (visitorData: any) => ({
    type: UserTypes.SET_VISITOR_DATA,
    payload: visitorData
})

export const saveTermsCondition = (data: boolean) => ({
    type: UserTypes.SAVE_TERMS_CONDITION,
    payload: data
})


export const savePhotoIDSuccess = (data: { photoId: string }) => {

    return ({
        type: UserTypes.USER_PHOTOID_SUCCESS,
        payload: data
    })

}

export const savePhotoID = (
    image: string,
) => {
    return (dispatch: Dispatch) => {
        let data = {
            photoId: image
        }
        dispatch(savePhotoIDSuccess(data));
    }
}

export const RequestStart = () => ({
    type: UserTypes.REQUEST_START,
    payload: 'string'
})

export const RequestSuccess = () => ({
    type: UserTypes.REQUEST_SUCCESS,
})

export const RequestFail = (error: string) => ({
    type: UserTypes.REQUEST_FAIL,
    payload: error
})

export const requestPermission = (
    visitorName: string,
    phone: string,
    email: string,
    meetingPurpose: string,
    startDate: Date,
    startTime: Date | undefined,
    endTime: Date | undefined,
    visitorPhoto: string | null,
    toMeet: number | undefined,
    orgId: number | undefined,
    visitorId: number | undefined,
    meetingId: number,
    sessionToken: string | undefined,
    tAndCFormChecked: boolean | null,
    visitorComments: string,
    kioskId: number | undefined,
    orgLogo: string | undefined,
    siteId: number,
    success: (autoApprovalEnabled: boolean) => void,
) => {
    return (dispatch: Dispatch) => {
        dispatch(RequestStart());
        let header = {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": localStorage.getItem("sessionToken"),
            }
        }

        let visitorInfo = {
            meetingId: meetingId,
            visitorId: visitorId,
            orgLogo: orgLogo,
        };


        let formData = new FormData();
        formData.append("visitorName", visitorName)
        if (phone) {
            formData.append("phone", phone)
        }
        if (email) {
            formData.append("email", email)
        }
        formData.append("meetingPurpose", meetingPurpose)

        if (toMeet) {
            formData.append("toMeetId", String(toMeet))
        }
        formData.append("siteId", String(siteId))
        if (visitorPhoto) {
            formData.append("visitorPhoto", base64ToFile(visitorPhoto, 'visitor_photo'))
        }

        if (String(tAndCFormChecked)) {
            formData.append("tAndCFormChecked", String(tAndCFormChecked))
        }

        if (visitorComments) {
            formData.append("visitorComments", visitorComments?.trim());
        }

        if (kioskId) {
            formData.append("kioskId", String(kioskId));
        }

        let year = startDate?.getFullYear();
        let month = startDate?.getMonth();
        let day = startDate?.getDate();

        let combinedStartDate = new Date(year, month, day, startTime?.getHours(), startTime?.getMinutes(), startTime?.getSeconds(), startTime?.getMilliseconds());
        let combinedEndDate = new Date(year, month, day, endTime?.getHours(), endTime?.getMinutes(), endTime?.getSeconds(), endTime?.getMilliseconds());

        formData.append("startDate", String(Math.round(combinedStartDate?.getTime() / 1000)));
        formData.append("endDate", String(Math.round(combinedEndDate?.getTime() / 1000)));

        Api1.post(`/v1/organisations/${orgId}/visitors/${visitorId || 0}/meeting/${meetingId || 0}/add/webApp`, formData, header).then((res: any) => {
            success && success(res?.data?.message?.autoApprovalEnabled)
            dispatch(RequestSuccess())
            dispatch(saveVisitorInfoSuccess(visitorInfo))
        }).catch(err => {
            if (err?.response) {
                dispatch(RequestFail(err?.response?.data?.message?.displayMessage || err?.response?.data?.message?.errorMessage || err?.response?.data?.message));
            } else {
                dispatch(RequestFail("Failed to send a request"));
            }
        })
    }
}

export const ChechkoutStart = () => ({
    type: UserTypes.USER_CHECKOUT_START,
    payload: null
})

export const ChechkoutSuccess = (message: string) => ({
    type: UserTypes.USER_CHECKOUT_SUCCESS,
    payload: message
})

export const ChechkoutFail = (error: string) => ({
    type: UserTypes.USER_CHECKOUT_FAIL,
    payload: error
})

export const Chechkout = (
    orgId: number | undefined,
    visitorId: number | undefined,
    meetingId: number | undefined,
    checkoutTime: Date,
    sessionToken: string | undefined,
) => {
    return (dispatch: Dispatch) => {
        dispatch(ChechkoutStart());
        let header = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${sessionToken}`
            }
        }
        let body = JSON.stringify({
            "checkoutTime": checkoutTime
        })

        Api1.post(`/v1/organisations/${orgId}/visitors/${visitorId}/meeting/${meetingId}/checkOut/webApp`, body, header).then((res: any) => {

            dispatch(ChechkoutSuccess(res.data.message))
        }).catch(err => {
            if (err?.response) {
                dispatch(ChechkoutFail(err?.response?.data?.message?.displayMessage || err?.response?.data?.message?.errorMessage || err?.response?.data?.message));
            } else {
                dispatch(ChechkoutFail("Failed to send a request"));
            }
        })
    }
}

export const updateComment = (visitorAdditionalInfo: string) => ({
    type: UserTypes.UPDATE_COMMENT,
    payload: { visitorAdditionalInfo }
})

const LinkToBase64 = (dispatch: Dispatch, fileLink: string, actionFunc: any) => {
    var request = new XMLHttpRequest();
    request.open('GET', fileLink, true);
    request.responseType = 'blob';
    request.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function (e: any) {
            let value = e.target.result;
            let encoded = value.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            dispatch(actionFunc({ terms: encoded }))
        };
    };
    request.send();
};