import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    @media (max-height: 380px) {
        min-height: 50rem;
        overflow-y: auto;
    }
`
export const Content = styled.div`
    display: flex; 
    flex: 0.7;
    gap: 1rem;
    align-items: center; 
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    flex-direction: column;
    max-width: 90vw;
    min-width: 270px;
    width: 22rem;
`

export const Title = styled.h4`
    color: rgb(0, 9, 65);
    font-weight: 400;
    font-size: 1.7rem;
    margin-block: 0;
    text-align: center;
`

export const Divider = styled.div`
    background: #FF781D;
    width: 95%;
    height: 0.2rem;
`

export const InfoText = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    color: #4F4F4F;
    margin-block: 0;
    font-family: 'Urbanist';
    margin-top: 0.5rem;
    line-height: 1.5rem;
`

export const Highlight = styled.span`
    font-weight: 500;
    color: #FF781D;
`

export const List = styled.li`
    font-size: 1.1rem;
    font-weight: 400;
    color: #4F4F4F;
    margin-block: 0;
    font-family: 'Urbanist';
    margin-top: 0.5rem;
`

export const TermsText = styled.p`
    font-family: Popins-Regular;
    color: #5F5F5F;
    font-size: 1rem;
    text-align: justify;
    text-justify: inter-word;
`

export const Title2 = styled.h1`
    color: #2484DC;
    font-size: 1.5rem;
    width: 100%;
    font-family: Lato-Bold;
    text-align: left;
    margin-bottom: 1rem;
    padding: 0 1rem;
`

export const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
`