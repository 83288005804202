import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { colors } from '../../Styles/colors';

// Define the props interface
interface TimePickerProps {
    label: string;
    value: Date | string | null;
    name: string;
    onChange: (event: { target: { name: string; value: Date | null } }) => void;
    ilocale?: string;
    disableInput?: boolean;
    [key: string]: any; // To allow any other props
}

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '8px',
        backgroundColor: colors.greyLight,
        borderColor: colors.greyBorder,
        paddingRight: '0',
        '&:before': {
            borderBottom: 'none',
        },
    },
    rootFocused: {
        borderColor: colors.greyBorder,
    },
    labelRoot: {
        color: colors.greyDark,
        fontFamily: 'Urbanist',
        fontSize: '0.9rem',
        marginTop: '0px',
        marginBottom: '0px',
    },
    labelFocused: {
        color: colors.greyDark,
    },
    icon: {
        fill: colors.greyDark,
    },
    formRoot: { marginTop: '0px', marginBottom: '0px' },
}));

const TimePicker: React.FC<TimePickerProps> = ({ label, value, name, onChange, ilocale, disableInput, ...other }) => {
    const classes = useStyles();

    const [selectedTime, setSelectedTime] = React.useState<Date | null>(new Date());

    const handleTimeChange = (time: Date | null) => {
        setSelectedTime(time);
        onChange({ target: { name, value: time } });
    };

    const TextFieldComponent = (props: any) => {
        return <TextField {...props} disabled={disableInput ? true : false} />;
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                id="time-picker"
                label={label}
                inputVariant="outlined"
                size="small"
                value={
                    value === null
                        ? null
                        : typeof value === 'string'
                        ? ''
                        : value || selectedTime
                }
                onChange={handleTimeChange}
                keyboardIcon={<AccessTimeIcon className={classes.icon} />}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                InputProps={{
                    classes: {
                        root: classes.root,
                        focused: classes.rootFocused,
                        input: classes.labelRoot,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused,
                    },
                }}
                TextFieldComponent={TextFieldComponent}
                fullWidth
                {...other}
            />
        </MuiPickersUtilsProvider>
    );
};

export default TimePicker;
