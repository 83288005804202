import styled from 'styled-components'

export const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row: auto;
    row-gap: 0.4rem;
    margin:  1.5rem 0;
`

export const Label = styled.p`
    margin:  0;
    color: #9B9BA3;
    font-size: 1rem;
    font-family: Lato-Regular;
`

export const TextArea = styled.textarea`
    position: relative;
    overflow: hidden;
    background-color: white;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    transition: all 200ms ease;
    border: 1px solid #AEAEB8 !important;
    padding: 0.5rem 1rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 350px !important;
    margin: 0;
    height: 3.5rem;
    font-size: 1rem;
    font-family: Lato-Regular;
    resize: none;
    @media (max-height: 670px) {
        height: 3rem;
    };
    &::placeholder {
        color: #9B9BA3 !important;
        font-size: 1rem;
        font-family: Lato-Regular;
        margin: 0;
        @media (max-height: 670px) {
            font-size: 0.8rem;
        }
    }
`