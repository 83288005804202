import { UserTypes } from './User-types';
import { UserActions } from './User-actions';


export interface UserState {
    loading: boolean,
    mobileNumberVerified: boolean,
    phone: string,
    otpMessage: string,
    error: string | null,

    visitorTypes: {
        unscheduledNew: boolean,
        unscheduledExisting: boolean,
        scheduled: boolean,
        checkedIn: boolean,
        waitingForApproval: boolean
    },
    meetingPurposes: string[],

    imageData: {
        format: string,
        height: number,
        quality: number,
        width: number
    },

    orgLogo: string,
    terms: string,
    showVisitorPhoto: boolean,
    organisationName: string,

    personsToMeet: {
        id: number,
        name: string,
    }[],

    email: string,
    orgId: number | undefined,
    organisationId: number | undefined,
    kioskId: number | undefined,
    visitorId: number | undefined,
    visitorName: string,
    visitorNameEnabled: boolean,
    visitorPhoneEnabled: boolean,
    visitorEmailEnabled: boolean,
    purposeOfMeetingEnabled: boolean,
    additionalInfoEnabled: boolean,
    startTimeEnabled: boolean,
    endTimeEnabled: boolean,
    hostAdditionalInfo: boolean,
    visitorAdditionalInfo: boolean,
    personToMeetEnabled: boolean,
    tAndCFormEnabled: boolean,
    tAndCFormChecked: boolean,
    visitorPhoto: string,
    meetingId: number,
    meetingPurpose: string,
    toMeet: {
        id: number,
        name: string,
    } | undefined,
    hostComments: string,
    visitorComments: string,

    sessionToken: string | undefined,

    message: string,
    checkoutMessage: string,
    type: string
    startTime: string | undefined,
    endTime: string | undefined,
    startDate: string,
    otpVerificationEnabled: boolean,
    siteId: number,
    siteName: string | undefined,
}


const INITIAL_STATE = {
    loading: false,
    mobileNumberVerified: false,
    phone: '',
    otpMessage: '',
    error: '',

    orgLogo: '',
    terms: '',
    showVisitorPhoto: true,
    organisationName: '',

    visitorTypes: {
        unscheduledNew: false,
        unscheduledExisting: false,
        scheduled: false,
        checkedIn: false,
        waitingForApproval: false
    },
    meetingPurposes: [],

    imageData: {
        format: "JPEG",
        height: 650,
        quality: 70,
        width: 650
    },

    personsToMeet: [],


    email: '',
    orgId: 0,
    organisationId: 0,
    kioskId: 0,
    visitorId: 0,
    visitorName: '',
    visitorNameEnabled: true,
    visitorPhoneEnabled: true,
    visitorEmailEnabled: true,
    purposeOfMeetingEnabled: true,
    personToMeetEnabled: true,
    tAndCFormEnabled: true,
    tAndCFormChecked: false,
    visitorPhoto: '',
    meetingId: 0,
    meetingPurpose: '',
    toMeet: {
        id: 0,
        name: ''
    },
    sessionToken: '',
    message: '',
    checkoutMessage: '',
    additionalInfoEnabled: false,
    hostAdditionalInfo: false,
    visitorAdditionalInfo: false,
    hostComments: "-",
    visitorComments: "",
    type: '',
    startTimeEnabled: false,
    endTimeEnabled: false,
    startTime: '',
    endTime: '',
    startDate: '',
    otpVerificationEnabled: false,
    siteId: 0,
    siteName: '',
}


const UserReducer = (
    state: UserState = INITIAL_STATE,
    action: UserActions
) => {
    switch (action.type) {
        case UserTypes.REGISTERUSER_START:
        case UserTypes.OTP_START:
        case UserTypes.REQUEST_START:
        case UserTypes.USER_CHECKOUT_START:
        case UserTypes.LINK_START:
        case UserTypes.EMAIL_LINK_START:
            return {
                ...state,
                loading: true,
                error: null,
                otpMessage: '',
            }
        case UserTypes.REGISTERUSER_PHONE_SUCCESS:
            return {
                ...state,
                loading: false,
                phone: action.payload,
                mobileNumberVerified: true,
                error: null
            }
        case UserTypes.REGISTERUSER_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                email: action.payload,
                error: null
            }
        case UserTypes.LOGIN_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case UserTypes.OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                visitorTypes: action.payload.visitorTypes,
                meetingPurposes: action.payload.meetingPurposes,
                email: action.payload.email,
                orgId: action.payload.orgId,
                visitorId: action.payload.visitorId,
                visitorName: action.payload.visitorName,
                visitorPhoto: action.payload.visitorPhoto,
                meetingId: action.payload.meetingId,
                meetingPurpose: action.payload.meetingPurpose,
                toMeet: action.payload.toMeet,
                personsToMeet: action.payload.personsToMeet,
                sessionToken: action.payload.sessionToken,
                hostComments: action.payload.hostComments,
                error: null,
                startTime: action.payload.startTime,
                endTime: action.payload.endTime,
                startDate: action.payload.startDate,
            }
        case UserTypes.LINK_SUCCESS:
            return {
                ...state,
                loading: false,
                orgLogo: action.payload.orgLogo,
                terms: action.payload.terms,
                showVisitorPhoto: action.payload.showVisitorPhoto,
                organisationName: action.payload.organisationName,
                orgId: action.payload.orgId,
                imageData: action.payload.imageData,
                error: null,
                visitorNameEnabled: action.payload.visitorNameEnabled,
                visitorPhoneEnabled: action.payload.visitorPhoneEnabled,
                visitorEmailEnabled: action.payload.visitorEmailEnabled,
                purposeOfMeetingEnabled: action.payload.purposeOfMeetingEnabled,
                personToMeetEnabled: action.payload.personToMeetEnabled,
                tAndCFormEnabled: action.payload.tAndCFormEnabled,
                photoId: action.payload.photoId,
                organisationId: action.payload.organisationId,
                kioskId: action.payload.kioskId,
                additionalInfoEnabled: action.payload.additionalInfoEnabled,
                hostAdditionalInfo: action.payload.hostAdditionalInfo,
                visitorAdditionalInfo: action.payload.visitorAdditionalInfo,
                startTimeEnabled: action.payload.startTimeEnabled,
                endTimeEnabled: action.payload.endTimeEnabled,
                meetingPurposes: action.payload.meetingPurposes,
                personsToMeet: action.payload.personsToMeet,
                otpVerificationEnabled: action.payload.otpVerificationEnabled,
                siteId: action.payload.siteId,
            }

        case UserTypes.LINK_SUCCESS_UPDATE_TERMS:
            return {
                ...state,
                terms: action?.payload?.terms || ''
            }
        case UserTypes.EMAIL_LINK_SUCCESS: {
            return {
                ...state,
                loading: false,
                orgLogo: action.payload.orgLogo,
                terms: action.payload.terms,
                showVisitorPhoto: action.payload.showVisitorPhoto,
                organisationName: action.payload.organisationName,
                orgId: action.payload.orgId,
                imageData: action.payload.imageData,
                error: null,
                visitorNameEnabled: action.payload.visitorNameEnabled,
                visitorPhoneEnabled: action.payload.visitorPhoneEnabled,
                visitorEmailEnabled: action.payload.visitorEmailEnabled,
                purposeOfMeetingEnabled: action.payload.purposeOfMeetingEnabled,
                personToMeetEnabled: action.payload.personToMeetEnabled,
                tAndCFormEnabled: action.payload.tAndCFormEnabled,
                organisationId: action.payload.organisationId,
                additionalInfoEnabled: action.payload.additionalInfoEnabled,
                hostAdditionalInfo: action.payload.hostAdditionalInfo,
                visitorAdditionalInfo: action.payload.visitorAdditionalInfo,
                startTimeEnabled: action.payload.startTimeEnabled,
                endTimeEnabled: action.payload.endTimeEnabled,
                otpVerificationEnabled: action.payload.otpVerificationEnabled,
                siteId: action.payload.siteId,
                siteName: action.payload.siteName,
            }
        }
        case UserTypes.USER_PHOTOID_SUCCESS:
            return {
                ...state,
                loading: false,
                visitorPhoto: action.payload.photoId,
                error: null
            }
        case UserTypes.REQUEST_SUCCESS: {
            return {
                ...state,
                loading: false,
            }
        }
        case UserTypes.USER_CHECKOUT_SUCCESS:

            return {
                ...state,
                loading: false,
                mobileNumberVerified: false,
                phone: '',
                otpMessage: '',
                error: '',

                visitorTypes: {
                    unscheduledNew: false,
                    unscheduledExisting: false,
                    scheduled: false,
                    checkedIn: false,
                    waitingForApproval: true
                },
                meetingPurposes: [],

                email: '',
                orgId: undefined,
                visitorId: undefined,
                visitorPhoto: '',
                meetingId: undefined,
                meetingPurpose: '',
                toMeet: {
                    id: undefined,
                    name: ''
                },
                sessionToken: undefined,
                checkoutMessage: action.payload
            }
        case UserTypes.REGISTERUSER_FAIL:
            return {
                ...state,
                loading: false,
                mobileNumberVerified: false,
                error: action.payload
            }
        case UserTypes.OTP_FAIL:
            return {
                ...state,
                loading: false,
                otpMessage: action.payload
            }
        case UserTypes.REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UserTypes.USER_CHECKOUT_FAIL:
        case UserTypes.LINK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UserTypes.SAVE_TERMS_CONDITION:
            return {
                ...state,
                tAndCFormChecked: action.payload
            }
        case UserTypes.UPDATE_EMAIL_LINK_SUCCESS:
            return {
                ...state,
                ...action?.payload
            }
        case UserTypes.UPDATE_COMMENT:
            return {
                ...state,
                visitorComments: action.payload.visitorAdditionalInfo,
            }

        case UserTypes.SET_VISITOR_DATA:
            return {
                ...state,
                ...action.payload,
                loading: false,
            }

        case UserTypes.SET_LOADER:
            return {
                ...state,
                loading: action.payload,
                otpMessage: ''
            }
        default:
            return state;
    }
};

export default UserReducer;